<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.BookCopies}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Book Copies</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Course Name</th>
                                                        <th>Book Name</th>
                                                        <th>Accession No</th>
                                                        <th>Accession Date</th>
                                                        <!-- <th>Status</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.BOOK_NAME}}</td>
                                                        <td>{{item.accession_no}}</td>
                                                        <th>{{item.accession_date| date:'dd/MM/yyyy'}}</th>
                                                        <!-- <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon"(click)="edit(item.ems_book_inventory_id)" >
                                                                <i class="fas fa-pen pt-1 mt-1"  ></i></span>
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddBookCopies}}</h4>
                <h4 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditBookCopies}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">{{labelName?.BookCategory}}<span
                                class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select
                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="BOOK_CATEGORY_ID">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of category" [value]="item.book_category_id">
                                            {{item.book_category_name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('BOOK_CATEGORY_ID').touched">
                                    <span class="text-danger" *ngIf="myForm.get('BOOK_CATEGORY_ID').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                               {{labelName.AccessionNo}} 
                               <!-- <span class="text-danger">*</span> -->
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text"  placeholder="" class="form-control" [(ngModel)]="accessionNo" [ngModelOptions]="{standalone: true}" disabled>
                                  
                                    <!-- <p class="pt-2">Please select the textbox and scan the Bar code</p> -->
                                </div>
                                <!-- <div *ngIf="myForm.get('category_name')?.touched">
                                    <span class="text-danger" *ngIf="myForm.get('category_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->

                            </div>
                        </div>

                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">{{labelName?.AccessionNo}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" #accessionNo placeholder="" class="form-control"
                                        formControlName="BAR_CODE_ID" [(ngModel)]="codeId">
                                    <div *ngIf="myForm.get('BAR_CODE_ID')?.touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('BAR_CODE_ID').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div> -->
                                    <!-- <p class="pt-2">Please select the textbox and scan the Bar code</p> -->
                                <!-- </div>

                            </div>
                        </div> -->


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">{{labelName?.NoOfCopies}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text"  placeholder="" class="form-control"
                                        formControlName="BOOK_NO_OF_COPIES" >
                                        </div>
                                    <div *ngIf="myForm.get('BOOK_NO_OF_COPIES')?.touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('BOOK_NO_OF_COPIES').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                    <!-- <p class="pt-2">Please select the textbox and scan the Bar code</p> -->
                                </div>

                            </div>
                        <!-- </div> -->

                        <div class="row">
                            <div class="col-md-3">

                            </div>
                            <!-- <div class="col-md-6">
                                <button class="btn btn-sm btn-save text-center" [disabled]="myForm.invalid"
                                    (click)=genrateBarCode()>Click Here To
                                    Generate Bar Code</button>
                            </div> -->
                            <div class="col-md-3">

                            </div>
                        </div>



                    </fieldset>
                </form>

            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>