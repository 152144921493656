import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assign-privileges',
  templateUrl: './assign-privileges.component.html',
  styleUrls: ['./assign-privileges.component.css']
})
export class AssignPrivilegesComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  userDropdown: Array<any> = [];
  courseDropdown: Array<any> = [];
  editData = {};
  labelName: any = {};
  isUserId: boolean = false;
  isCourse: boolean = false;
  headingName: any = {};
  workItemId:any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadAssignedPrivileges();
    this.getUsers();
    this.getCourse();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      "is_management": ['', Validators.required],
      "userid": ['', Validators.required],
      "management_course": ['',]
    })
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadAssignedPrivileges() {
    this.CommonService.getCall(`Registration/GetAssignPrivilagesList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  getUsers() {
    this.userDropdown = [];
    let payload = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "Role": localStorage.getItem('RoleId')
    }
    this.CommonService.postCall('Registration/GetUsersByRole', payload).subscribe((res: any) => {
      this.userDropdown = res;
    })
  }

  getCourse() {
    this.courseDropdown = [];
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.courseDropdown = res;
    })
  }

  managementChange(val: any) {
    let management = val;
    if (management == 1) {
      this.isUserId = true;
      this.isCourse = false;
    } else {
      this.isUserId = true;
      this.isCourse = true;
    }
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    value['management_course'] = value.management_course || 0;
    if (value.is_management == 2 && value.management_course == 0) {
      this.toastr.warning('Please Select Course')
    } else {
      value['tnt_code'] = localStorage.getItem('TenantCode');
      value['created_by'] = localStorage.getItem('UserId');
      if (this.isEdit) {
        value['modified_by'] = localStorage.getItem('UserId');
        value['userid'] = this.editData['userid'];
        this.CommonService.postCall('Registration/UpdateAssignPrivilages', value).subscribe((res: any) => {
          this.loadAssignedPrivileges();
          this.toastr.success("Privileges Updated Successfully");
          document.getElementById('md_close').click();
        }, err => {
          this.toastr.error(err.error ? err.error : 'Privileges Not Updated')
        })
      } else {
        this.CommonService.postCall('Registration/UpdateAssignPrivilages', value).subscribe((res: any) => {
          this.loadAssignedPrivileges();
          this.toastr.success("Privileges Assigned Successfully");
          document.getElementById('md_close').click();
        }, err => {
          this.toastr.error(err.error ? err.error : 'Privileges Not Assigned')

        })
      }
    }
  }


  edit(userId: any) {
    this.isEdit = true;
    this.CommonService.getCall(`Registration/GetAssignPrivilages/${userId}`).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
        this.managementChange(this.editData['is_management']);
      }, 10);
    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(data[key])
    });
    // this.myForm.patchValue({
    //   is_management: data['is_management'],
    //   userid: data['userid'],
    //   management_course: data['management_course'],      
    // })
  }

  close() {
    this.isEdit = null;
    this.isUserId = null;
    this.isCourse = null;
    this.editData = {};
    this.myForm.reset();
  }

}
