import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-physical-stocktaking',
  templateUrl: './add-physical-stocktaking.component.html',
  styleUrls: ['./add-physical-stocktaking.component.css']
})
export class AddPhysicalStocktakingComponent implements OnInit {
  table: Array<any> = [];

  constructor() { }

  ngOnInit(): void {
  }

  add(){

  }

  Save(){

  }

  close(){
    window.history.back();
  }

}
