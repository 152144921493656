// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serviceUrl:"https://lmsemsqa.samvaad.pro/api/",
  serviceUrl:"https://lmsinternal.samvaadpro.com/api/",
  // fileUrl: "https://lmsemsqa.samvaad.pro/api",
  fileUrl: "https://lmsinternal.samvaadpro.com/api/",
  SOCKET_ENDPOINT: 'http://localhost:3000',
  SamvaadUrl:'https://scheduling.samvaad.pro/conference/',
};
//http://localhost:56608/swagger/index.html   serviceUrl:"https://lmsemsqa.samvaad.pro/api/",

//  serviceUrl:"http://lmsav.dhanushinfotech.com/",https://lmsqa.dhanushinfotech.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
