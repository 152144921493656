import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-food-group',
  templateUrl: './food-group.component.html',
  styleUrls: ['./food-group.component.css']
})
export class FoodGroupComponent implements OnInit {

  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  foodGroup: any;
  editData: any;
  constructor(private toastr: ToastrService, private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.load();


  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      foodgroup_code:['',Validators.required],
      foodgroup_name:['',Validators.required],
      foodgroup_description:['',Validators.required],
      foodgroup_status:['',Validators.required]

    })
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  load() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('FoodGroup/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }


  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }

  onSubmit(form) {
    let value: any = form.value;
    value['foodgroup_createdby']= localStorage.getItem('UserId');
    value['foodgroup_modifiedby']=localStorage.getItem('UserId');
    value['tnt_code']=localStorage.getItem('TenantCode');
  if(this.isEdit){
    value['foodgroup_modifiedby']=localStorage.getItem('UserId');
    value['foodgroup_id'] =this.foodGroup
     this.CommonService.postCall('FoodGroup/Update',value).subscribe((res:any)=>{
      this.load();
      this.toastr.success("Food Group Updated Successfully");
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Food Group Not Updated')
    })
  }else{
    if (this.table.find((e: any) => e.foodgroup_name == value.foodgroup_name)) {
      this.toastr.warning('Food Group Name Already Exists');
      return;
    }
    this.CommonService.postCall('FoodGroup/Create',value).subscribe((res:any)=>{
      this.load();
      this.toastr.success("Food Group Created Successfully");
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Food Group Not created')
    })

  }
}

  edit(id: any) {
    this.isEdit=true;
    this.foodGroup=id
     let payload={
        "foodgroup_id":id
     }
     this.CommonService.postCall('FoodGroup/Get',payload).subscribe((res:any)=>{
     this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }

  dataTransfer(data){
    this.myForm.patchValue({
      foodgroup_code:data['foodgroup_code'],
      foodgroup_name:data['foodgroup_name'],
      foodgroup_description:data['foodgroup_description'],
      foodgroup_status:data['foodgroup_status']
    })

  }

  close() {

  }
}
