<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.AttendanceReportList}}</h5>
                                    </div>
                                    <div class="row">
                                        <div *ngIf='isSuperAdmin' class="col-md-3">
                                            <label for="sel1">{{labelName.TenantName}} : </label>
                                            <select [(ngModel)]='tId ' (change)="loadCampusDropdown()"
                                                class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                    {{item.TNT_NAME}}</option>
                                            </select>

                                        </div>

                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">

                                                    <div class="form-group col-md-3 ">
                                                        <label>{{labelName.Month}}</label>
                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group">
                                                                <input placeholder="Search Date"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    type="date" formControlName="date">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Campus}} </label>
                                                        <div class="input-group">
                                                            <select (change)="campusChange()"
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of campusDropdown"
                                                                    [value]="item.campus_id">
                                                                    {{item.campus_name}}</option>
                                                            </select>

                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Course}} </label>


                                                        <div class="input-group">
                                                            <select formControlName="class_id" [(ngModel)]='courceId '
                                                                (change)="courceChange($any($event).target.value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select
                                                                </option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Section}} </label>


                                                        <div class="input-group">
                                                            <select formControlName="section_id"
                                                                (change)="sectionChange()"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.COURSESHD_ID'>
                                                                    {{schedul.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div class="form-group col-md-3 pt-4">
                                                        <label>{{labelName.AttendanceType}} </label>

                                                        <div class="input-group">
                                                            <select formControlName="status"
                                                                (change)="attendanceChange()"
                                                                placeholder="Select Attendance Type"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select
                                                                </option>
                                                                <option value='Present'>Present</option>
                                                                <option value='Absent'>Absent</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" style="padding-top: 46px;">
                                                        <button type="button" [disabled]="myForm.invalid"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <!-- <div>
                                            <button type="button" [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                class="btn btn-sm  btn-save"><i class="fas fa-search"></i></button>
                                        </div> -->





                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable [dtOptions]="dtOptions"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Student Name</th>
                                                        <th>Class</th>
                                                        <th>Section</th>
                                                        <!-- <th> Campus</th> -->
                                                        <!-- <th>Type</th>
                                                        <th>Post type</th>                                                         -->
                                                        <th>Status</th>
                                                        <th> Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.student_name }}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.courseshd_name }}</td>
                                                        <!-- <td>{{item.campus_name }}</td> -->
                                                        <!-- <td>{{item.ACADEMIC_DESCRIPTION}}</td>
                                                        <td>{{item.ACADEMIC_NAME}}</td> -->
                                                        <td>{{item.status == true?'Present':'Absent' }}</td>
                                                        <td>{{item.created_on | date:'yyyy-MM-dd' }}</td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length" style="color: red;">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>