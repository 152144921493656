import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-daily-menu-schedule',
  templateUrl: './daily-menu-schedule.component.html',
  styleUrls: ['./daily-menu-schedule.component.css']
})
export class DailyMenuScheduleComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = null;
  isDisable: boolean = false;
  table: Array<any> = [];
  foodGroupList: Array<any> = [];
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  dropdownItemsList: { fooditem_id: number; fooditem_name: string; }[] = [];
  dropdownSettings: any = {};
  selectedItemsList: any = {};
  mealType = [{ id: 1, name: 'Breakfast' }, { id: 2, name: 'Lunch' }, { id: 3, name: 'Dinner' }];
  weekdays = [{ id: 1, name: 'Sunday' }, { id: 2, name: 'Monday' }, { id: 3, name: 'Tuesday' }, { id: 4, name: 'Wednesday' }, { id: 5, name: 'Thursday' }, { id: 6, name: 'Friday' }, { id: 7, name: 'Saturday' }];
  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.load();
    this.loadFoodGroup();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      dms_type: ['', [Validators.required,]],
      dms_weekday: ['', Validators.required],
      foodgroup_id: ['', [Validators.required,]],
      fooditem_id: ['', Validators.required],
      // dms_status:['true',Validators.required]
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'fooditem_id',
      textField: 'fooditem_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onItemSelect(e) { }
  onSelectAll(e) { }

  load() {
    this.activiceSpinner();
    this.CommonService.postCall(`FoodDailyMenuSchedule/GetList`, { tnt_code: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  loadFoodGroup() {
    this.foodGroupList = [];
    this.CommonService.postCall(`FoodGroup/GetList`, { tnt_code: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.foodGroupList = res;
    })
  }

  changeFoodGroup(foodGroupId) {
    this.dropdownItemsList = [];
    this.CommonService.postCall(`FoodItem/GetListItem`, { foodgroup_id: foodGroupId }).subscribe((res: any) => {
      this.dropdownItemsList = res;
    })
  }

  getMealName(id: any) {
    return this.mealType.find(f => f.id == id)?.name ?? '';
  }

  getWeekdayName(id: any) {
    return this.weekdays.find(f => f.id == id)?.name ?? '';
  }


  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      // "dms_code": 1,
      // "dms_name": "FoodMenu",
      "dms_type": value.dms_type,
      "dms_weekday": value.dms_weekday,
      "foodgroup_id": value.foodgroup_id,
      "fooditem_id": value.fooditem_id,
      "dms_status": "true",
      "tnt_code": localStorage.getItem('TenantCode')
    }
    if (this.isEdit) {
      payload['dms_modifiedby'] = localStorage.getItem('UserId');
      payload['dms_modifieddate'] = new Date();
      payload['dms_id'] = this.editData.dms_id;
      this.CommonService.postCall('FoodDailyMenuSchedule/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Daily Menu Schedule Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Academic Not Updated')
      })
    } else {
      payload['dms_createdby'] = localStorage.getItem('UserId');
      payload['dms_createddate'] = new Date();
      this.CommonService.postCall('FoodDailyMenuSchedule/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Daily Menu Schedule Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error.message ? err.error.message : 'Already Exist')

      })
    }


  }
  edit(dmsId) {
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "dms_id": dmsId
    }
    this.CommonService.postCall('FoodDailyMenuSchedule/Get', payLoad).subscribe((res: any) => {
      this.changeFoodGroup(res.foodgroup_id);
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    })
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
  }

  print() { }

  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }

  close1() { }
}
