import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-physical-stock-taking',
  templateUrl: './physical-stock-taking.component.html',
  styleUrls: ['./physical-stock-taking.component.css']
})
export class PhysicalStockTakingComponent extends BaseComponent implements OnInit {
  stockList: Array<any> = [];

  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }


  ngOnInit(): void {
  }

  add(){
    this.route.navigate(['home/PhysicalStockTaking/add']);
  }

}
