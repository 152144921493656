import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-material-subgroup',
  templateUrl: './material-subgroup.component.html',
  styleUrls: ['./material-subgroup.component.css']
})
export class MaterialSubgroupComponent implements OnInit {
  materialsubLst: Array<any> = [];
  grpLst: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')

  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    this.getGrpNames();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      MATERIALSUBGROUP_ID: [0],
      NAME: ['', Validators.required],
      MATERIALGROUP_ID: ['', Validators.required],
      STOCK_ACCOUNT_CODE: [],
      ASSET_ACCOUNT_CODE: [],
      STATUS: [1],
      CREATED_BY: [],
      TNT_CODE: [],
      MODIFIED_BY: []
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`MaterialSubGroup/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.materialsubLst = [];
      setTimeout(() => {
        this.materialsubLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  getGrpName(id: any) {
    return this.grpLst.find(f => f.materialgroup_id == id)?.name ?? '';
  }

  getGrpNames() {
    this.CommonService.getCall(`MaterialGroup/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.grpLst = res.data;
      let activeNames = [];
      activeNames = res.data.filter(f => f.status == true)
      this.grpLst = activeNames;
    })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "MATERIALSUBGROUP_ID": 0,
      "NAME": value.NAME,
      "MATERIALGROUP_ID": +value.MATERIALGROUP_ID,
      "STOCK_ACCOUNT_CODE": value.STOCK_ACCOUNT_CODE,
      "ASSET_ACCOUNT_CODE": value.ASSET_ACCOUNT_CODE,
      "STATUS": +value.STATUS,
      "CREATED_BY": +this.userId,
      "TNT_CODE": +this.tenantCode,
      "MODIFIED_BY": 0,
    }

    if (this.isEdit) {
      payload.MATERIALSUBGROUP_ID = this.editData.MATERIALSUBGROUP_ID;
      payload.MODIFIED_BY = +this.userId,
        this.CommonService.postCall('MaterialSubGroup/Update', payload).subscribe((res: any) => {
          this.toastr.success("MaterialSubGroup Updated Successfully");
          document.getElementById('md_close').click();
          this.loadData();
        }, err => {
          this.toastr.error(err.error ? err.error : 'MaterialSubGroup Not Updated')
        })
    } else {
      this.CommonService.postCall('MaterialSubGroup/Create', payload).subscribe((res: any) => {
        if (res.status == false) {
          this.toastr.warning(res.message, "Material Sub Group");
          return;
        }
        this.toastr.success("MaterialSubGroup Created Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'MaterialSubGroup Not created')
      })
    }
  }

  edit(data: any) {
    this.isEdit = true;
    this.CommonService.getCall(`MaterialSubGroup/GetById/${data?.MATERIALSUBGROUP_ID}`).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.datatransform()
      } else {
        this.editData = res.data;
        this.datatransform()
      }
    }, err => { }
    )
  }

  datatransform() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value = this.editData[key];
      if (value != undefined) control.setValue(value);
    });
  }

  add() {
    this.formInit();
    this.editData = {};
    this.isEdit = false;
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
  }
}
