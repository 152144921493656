<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Material Master</h5>
                                        
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1 btn-sm"
                                                (click)="add()">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Material Group Name</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="materialMasterChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let materials of matNameList"
                                                            [value]='materials.materialgroup_id'>
                                                            {{materials.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Material Sub Group Name</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="materialMasterChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let matGpName of matGpNameList"
                                                            [value]='matGpName.MATERIALSUBGROUP_ID'>
                                                            {{matGpName.NAME}}</option>
                                                    </select>
                                                </div> 
                                            </div>
                                        </div>&nbsp;

                                        <div  class="table-responsive">
                                            <table id="materialMaster" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top"
                                                    style="background-color: #624FD7; color:#ffffff;">
                                                    <tr style="white-space: nowrap;">
                                                        <th> Material Name </th>
                                                        <th> Abbreviation </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let material of masterList">
                                                        <td>{{material.name}}</td>
                                                        <td>{{material.abbreviation}}</td>
                                                        <td>{{material.status}}</td>
                                                        <td style="display: flex;">
                                                            <span src="" class="edit_icon" (click)="edit(material)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>