import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { UpperCasePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-startexam',
  templateUrl: './startexam.component.html',
  styleUrls: ['./startexam.component.css']
})
export class StartexamComponent implements OnInit {
  time: any;
  assessment: any = {}
  questions: Array<any> = [];
  AssessmentInformation: any = {};
  assementData: any = {};
  count: number = 0;
  clickNext: number = 0;
  isNext: boolean = false;
  isPrev: boolean = true;
  isSubmited: boolean = false;
  answers: Array<any> = [];
  result: any;
  radioStatus: boolean;
  changeQuestion: any;
  srno: any;
  numbool: boolean = false;
  options: Array<any> = [];
  firstValue: any;
  mapElement: Array<any> = [];
  item: any;
  previousIndex: any;
  isFirst: boolean = true;
  firstIndex: any;
  val: any;
  constructor(private CommonService: CommonService, private route: Router, private toastr: ToastrService) {
    this.assessment = CommonService.assessment
    if (!Object.keys(this.assessment).length) {
      route.navigate(['home/postassessment'])
    } else {
      this.getAssessment();
    }
  }

  ngOnInit(): void {
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getAssessment() {
    let data: any = {
      AssessmentCourseId: this.assessment.ASSESSMENT_COURSE_ID,
      AssessmentId: this.assessment.ASSESSMENT_ID,
      AssessmentType: 1,
      AssessmentUserId: localStorage.getItem('UserId'),
      CourseScheduleId: this.assessment.ASSESSMENT_COURSESECHD_ID,
      ScheduleId: this.assessment.SA_ID,
      TenantCode: localStorage.getItem('TenantCode'),
      NoOfQuestions: this.assessment.ASSESSMENT_NO_OF_QUESTIONS
    }
    this.activeSpinner();
    this.CommonService.takeExam(data).subscribe((res: any) => {
      this.deactivateSpinner();
      if (typeof res == 'string') {
        this.toastr.warning(res);
      } else {
        this.assementData = res;
        this.AssessmentInformation = res['AssessmentInformation'][0];
        this.dataTransfer()
      }
    }, (e) => {
      this.deactivateSpinner();
      if (e.error.text == "Your no.of attempts left is 0,please contact Administrator for further assistance") {
        // alert(e.error);
        this.toastr.error(e.error.text);
        this.route.navigate(['home/postassessment'])
      } else {
        this.toastr.error(e.error.text);
        this.route.navigate(['home/postassessment'])
      }
    })
  }
  dataTransfer() {
    let t = this.AssessmentInformation.ASSESSMENT_TIMINGFORASSESSMENT * 60;
    this.time = t;
    let data: Array<any> = [];
    let questions = this.assementData.AssessmentQuestions;
    let options: Array<any> = this.assementData.AssessmentOptions;
    questions.map((q) => {
      let qId = q.QUESTION_ID;
      let op = options.filter(option => option.QUESTION_ID == qId) || []
      q.options = op;
    })
    this.questions = questions;
    this.mapElement = this.questions.map((e: any) => ({ ...e, isSelected: false, isReview: false, isAnswered: false }));
    // this.mapElement.map((e: any) => {
    //   e.options.map((res: any) => { res.selectedOption = false })
    // });
    this.mapElement[0].isSelected = true;
    this.previousIndex = 0;
    // this.srno = this.firstValue.SRNO;
    // this.changeQuestion = this.firstValue.QUESTION_QUESTION;
    this.questions[0].isShow = true;
    this.selectedQuestion(0);
  }

  handleEvent(event) {
    if (event.action == "done") {
      this.toastr.info('Time Out');

      setTimeout(() => this.submit(), 200)
    }
  }

  next() {
    // this.isPrev = false;
    // this.questions[this.count || this.firstValue].isShow = false;
    // this.count++;
    // this.firstValue++
    // this.questions[this.count || this.firstValue].isShow = true;
    // if (this.count == this.questions.length - 1) {
    //   this.isNext = true
    // }



  }
  prev() {
    // this.isNext = false
    // this.questions[this.count].isShow = false;
    // this.count--;
    // this.questions[this.count].isShow = true;
    // if (!this.count) {
    //   this.isPrev = true;
    // }
  }

  submit() {
    this.activeSpinner();
    let data: any = {
      AssessmentId: this.assessment.ASSESSMENT_ID,
      AssessmentType: 1,
      CourseId: this.assessment.ASSESSMENT_COURSE_ID,
      SaId: this.assessment.SA_ID,
      PassPercentage: this.assessment.ASSESSMENT_MINPERCENTAGE,
      AssessmentAnswers: []
    }
    let answers = [];
    this.questions.forEach((item) => {
      let obj: any = {};
      obj.TypeId = item.TYPEID;
      obj.QuestionAnswer = item.QUESTION_ANSWER
      obj.QuestionId = item.QUESTION_ID;
      obj.QuestionMarks = item.ACTUALMARKS;
      obj.Selected = item.options.find(e => !e.IS_OPTION_RANDOM == false)?.IS_OPTION_RANDOM || '';
      answers.push(obj)
    });
    data.AssessmentAnswers = answers;
    this.CommonService.setAssessments(data).subscribe((res: any) => {
      this.isSubmited = true;
      this.clickNext = 0;
      this.deactivateSpinner();
      if (Array.isArray(res)) {
        this.result = res[0];
        this.checkAnswers();
      } else {
        this.toastr.success(res.message);
      }
    }, (err) => {
      this.deactivateSpinner();
      this.toastr.error(err.error);
    })
  }


  selectedQuestion(index: any) {

    if (index == 'next') {
      this.isPrev = false;
      this.clickNext = this.clickNext + 1;
      this.firstIndex = this.mapElement[this.clickNext];
      this.changeQuestion = this.firstIndex.QUESTION_QUESTION;
      this.srno = this.firstIndex.SRNO;
      this.options = this.firstIndex.options;
      let indexValue = this.firstIndex.SRNO;
      let lastIndex = indexValue - 1;
      let previousQtn = this.mapElement.filter((e: any) => e.SRNO == lastIndex);
      if (!previousQtn[0].isReview) {
        previousQtn[0].isReview = true;
      }
      if (this.clickNext == this.questions.length - 1) {
        this.isNext = true;
      }

    } else if (index == 'prev') {
      this.isNext = false;
      this.clickNext = this.clickNext - 1;
      let item = this.mapElement[this.clickNext];
      this.changeQuestion = item.QUESTION_QUESTION;
      this.srno = item.SRNO;
      this.options = item.options;
      if (!this.clickNext) {
        this.isPrev = true;
      }
    } else if (index == 'options') {
      let sId = this.mapElement.filter((e: any) => e.SRNO == this.firstIndex.SRNO);
      if (!sId[0].isAnswered) {
        sId[0].isAnswered = true;
      }

      // this.mapElement.map((e: any) => {
      //   e.options.map((res: any) => { res.selectedOption = false })
      // });

      // this.val = sId[0].options.find((e: any) => e.QSTOPT_OPTION == event);
      // this.val = this.mapElement.filter((e:any)=>e.options.find((ele:any)=>ele.QSTOPT_OPTION == event));

      // this.val.selectedOption = true;
      // console.log(this.val);




      // console.log(sId, '====================', this.mapElement);




      // Update the completion status of Project 2 for employee John

      // let updatedAnswer = this.mapElement.map(e => {
      //   if (e.SRNO == this.firstIndex.SRNO) {
      //     let updatedOptions = e.options.map(op => {
      //       if (op.QSTOPT_OPTION == event) {
      //         return { ...op, selectedOption: true };
      //       } else {
      //         return op;
      //       }
      //     });
      //     return { ...e, options: updatedOptions };
      //   } else {
      //     return e;
      //   }
      // });
      // this.mapElement = updatedAnswer


      // if (!this.firstIndex.isAnswered) {
      //   this.firstIndex.isAnswered = true;
      // }
      // if (!this.item?.isAnswered && !this.item?.isAnswered) {
      //   this.item.isAnswered = true;
      // }
      // console.log(answer);

      // answer = true;
      // if (answer && event.target.checked) {
      //   document.getElementById('qtnBtn_'+index).style.backgroundColor = '#00FF00';
      // }

    } else {

      this.clickNext = index;
      // let item = this.mapElement[index];
      this.firstIndex = this.mapElement[index];
      this.firstValue = index;
      this.changeQuestion = this.firstIndex.QUESTION_QUESTION;
      this.srno = this.firstIndex.SRNO;
      this.options = this.firstIndex.options;
      this.isPrev = false;
      this.isNext = false;

      if (this.clickNext == this.questions.length - 1) {
        this.isNext = true
      }
      if (!this.clickNext) {
        this.isPrev = true;
      }
      let previousQtn = this.mapElement.filter((e: any, i: any) => i == this.previousIndex);
      if (this.isFirst && this.previousIndex == 0 && index == 0) {
        return this.isFirst = false;
      } else {
        if (!previousQtn[0].isReview) {
          previousQtn[0].isReview = true;
        }
        this.previousIndex = index;
      }
    }

    // let item: any = this.mapElement.find((item: any) => item.isSelected == true);
    // console.log(item);

    // let ads = this.questions.find((e: any) => e.SRNO == item.SRNO);
    // this.srno = ads.SRNO;
    // this.changeQuestion = ads.QUESTION_QUESTION;


    // this.numbool = true;

  }
  checkAnswers() {
    let a_id = this.assessment.ASSESSMENT_ID;
    let sa_id = this.assessment.SA_ID
    this.CommonService.checkAnswers(a_id, sa_id).subscribe((res: any) => {
      this.answers = res;
    })
  }
  confirm() {
    var r = confirm("Press 'OK' to End Exam and 'Cancel' to Continue Exam.");
    if (r == true) {
      this.submit()
    } else {

    }
  }
}
