<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.DailyMenuSchedule}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Meal Type</th>
                                                        <th>Weekday</th>
                                                        <th>Food Group</th>
                                                        <!-- <th>Item List</th> -->
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{getMealName(item.dms_type)}}</td>
                                                        <td>{{getWeekdayName(item.dms_weekday)}}</td>
                                                        <td>{{item.foodgroup_id}}</td>
                                                        <!-- <td>{{item.WeightVolume}}</td> -->
                                                        <td style="display: flex;"> <button type="button"
                                                                data-bs-toggle="modal" data-bs-target="#myModal1"
                                                                class="btn btn-sm  btn-save">Preview</button>
                                                            &nbsp;&nbsp;
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.dms_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddDailyMenuSchedule}}
                </h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100">{{headingName.EditDailyMenuSchedule}}
                </h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.MealType}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="dms_type">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of mealType" [value]='item.id'>
                                            {{item.name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('dms_type').touched">
                                    <span class="text-danger" *ngIf="myForm.get('dms_type').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Weekday}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="dms_weekday">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of weekdays" [value]='item.id'>
                                            {{item.name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('dms_weekday').touched">
                                    <span class="text-danger" *ngIf="myForm.get('dms_weekday').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.FoodGroup}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="foodgroup_id"
                                        (change)="changeFoodGroup($any($event.target).value)">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of foodGroupList" [value]='item.foodgroup_id'>
                                            {{item.foodgroup_name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('foodgroup_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('foodgroup_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.ItemList}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="fooditem_id">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of dropdownItemsList" [value]='item.fooditem_id'>
                                            {{item.fooditem_name}}</option>
                                    </select>
                                    <!-- <ng-multiselect-dropdown style="width:96%" formControlName="fooditem_id"
                                        [placeholder]="'Select'" [settings]="dropdownSettings"
                                        [data]="dropdownItemsList" (onSelect)="onItemSelect($any($event.target).value)"
                                        (onSelectAll)="onSelectAll($any($event.target).value)"
                                        [(ngModel)]='selectedItemsList'>
                                    </ng-multiselect-dropdown> -->
                                </div>
                                <div *ngIf="myForm.get('fooditem_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('fooditem_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Status}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="dms_status">
                                        <option value="" selected>Select</option>
                                        <option value='true' selected>Active</option>
                                        <option value='false'>InActive </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('dms_status').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('dms_status').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div> -->


                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.MenuList}}
                </h6>

                <button type="button" (click)="close1()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-save" (click)="print()">Print</button>

                <button type="button" class="btn btn-sm btn-danger" id='md_close1' (click)="close1()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>