import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-indent',
  templateUrl: './add-indent.component.html',
  styleUrls: ['./add-indent.component.css']
})
export class AddIndentComponent implements OnInit {
  myForm: FormGroup;
  isWithoutIndent: Boolean = false;
  isStockTransfer: Boolean = false;

  constructor(private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.formInit();

  }

  formInit() {
    this.myForm = this.fb.group({
      dept: [''],
      indNo: [''],
      centers: [''],
      pending_Request: [''],
      isIndent: ['']
    })
  }

  isButtonchange(value: any) {
    if(value.value == '1') {
      this.isWithoutIndent = false;
      this.isStockTransfer = false;

    }
    if (value.value == '2') {
      this.isWithoutIndent = true;
      this.isStockTransfer = false;
    } else {
      this.isWithoutIndent = false;
      this.isStockTransfer = true;
    }
  }



  Save(form: FormGroup) {

  }
  close() {
    window.history.back();
  }


}
