import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-quotation-entry',
  templateUrl: './add-quotation-entry.component.html',
  styleUrls: ['./add-quotation-entry.component.css']
})
export class AddQuotationEntryComponent implements OnInit {
  table: Array<any> = [];
  constructor() { }

  ngOnInit(): void {
  }
  
  Save(){

  }

  close(){
    window.history.back();

  }
}
