import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare let $: any;

@Component({
  selector: 'app-indent-issues',
  templateUrl: './indent-issues.component.html',
  styleUrls: ['./indent-issues.component.css']
})
export class IndentIssuesComponent implements OnInit {
  strLst: Array<any> = [];
  indetOrderGetlist: Array<any> = [];
  myForm: FormGroup;
  myForm1: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')
  showtable: boolean = true;
  showIMandMB: boolean = true;
  showtableIndent: boolean = false;
  departmentList: Array<any> = [];
  indentPendingList: Array<any> = [];
  checkedPendingList: any[];
  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    this.getDepartments();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      fromDate: [((new Date()).toISOString().substring(0, 10))],
      toDate: [((new Date()).toISOString().substring(0, 10))],
      storeId: ['', Validators.required],
      DeptId: ['', Validators.required],
      isssueStatus: [0]
    });
    this.myForm1 = this.fb.group({
      date: [((new Date()).toISOString().substring(0, 10))],
      indent: [''],
      DeptId: [''],
      status: [''],
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`StoreMaster/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.strLst = [];
      setTimeout(() => {
        this.strLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  getDepartments() {
    this.activeSpinner();
    this.CommonService.postCall(`Department/dropdown`, { "TNT_CODE": this.tenantCode }).subscribe((res: any) => {
      this.departmentList = [];
      setTimeout(() => {
        this.departmentList = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  checkAll(event: any) {
    if (event.target.checked == true) {
      this.indentPendingList.forEach(a => { a.checked = true });
    }
    else {
      this.indentPendingList.forEach(a => { a.checked = false });
    }
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    let payload = {
      "isssueStatus": parseInt(value.isssueStatus),
      "IndentType": 0,
      "fromDate": value.fromDate,
      "toDate": value.toDate,
      "DeptId": parseInt(value.DeptId),
      "storeId": parseInt(value.storeId)
    }
    this.activeSpinner();
    this.CommonService.postCall(`IndentIssues/GetIndentIssuesBySearch`, payload).subscribe((res: any) => {
      this.indetOrderGetlist = [];
      if (res.data.length == 0) {
        this.toastr.warning('No data available for selected records');
        this.deactivateSpinner();
        return;
      }
      setTimeout(() => {
        this.indetOrderGetlist = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  changeIndent(value) {
    let val = this.myForm.getRawValue();
    if (value == 1) {
      this.activeSpinner();
      this.CommonService.getCall(`IndentIssues/GetPeningIndentOrders/${val.storeId}`).subscribe((res: any) => {
        this.indentPendingList = [];
        setTimeout(() => {
          this.indentPendingList = res.data.map(v => ({ ...v, checked: false }));
          // this.meterialList = res.data.map(v => ({ ...v, checked: false }));
        }, 10)
        this.deactivateSpinner();
      }, e => { this.deactivateSpinner() })
    }
    this.deactivateSpinner();
  }


  onSubmit1(form: FormGroup) {
    let value: any = form.value;
    let payload = {

      "indentissue_id": 0,
      "indentissue_no": "",
      "indentissue_date": value.date,
      "indentissue_store_id": value.StoreId,
      "indent_issue_value": 0,
      "status": 0,
      "created_by": this.userId,
      "modified_by": this.userId,
      "modified_date": new Date(),
      "department_id": value.DeptId,
      "jv_number": "",
      "jv_date":new Date(),
      "receive_status": 0,
      "tnt_code": this.tenantCode,
      "IndentIssueDetail": this.checkedPendingList
    }
    this.CommonService.postCall('IndentOrder/Create', payload).subscribe((res: any) => {
      if (res.status == false) {
        this.toastr.warning(res.message, "Store Master");
        return;
      }
      this.toastr.success("Indent Order Created Successfully");
      this.loadData();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Indent Order Not created')
    })
    
  }



  add() {
    // this.formInit();
    this.editData = {};
    this.isEdit = false;
    this.showtable = false;
    this.showIMandMB = false
  }

  close() {
    this.showtable = true;
    this.checkedPendingList=[];
    this.isEdit = false;
    this.myForm.reset();
    this.myForm1.reset();
  }

  cancel() {
    this.router.navigate(['/home/dashboard']);
  }

  closeModal() {
    this.showtable = false;
  }

  tableIMandMB() {
    this.showIMandMB = true;
  }

  addIndentMaterials() {
    $('#myModal1').modal('show');
  }

  onPopup() {
    $('#myModal').modal('show');
    this.showIMandMB = false
  }

  // onPopup1() {
  //   $('#myModal').modal('show');
  // }

  ok() {
    this.checkedPendingList = [];
    this.checkedPendingList = this.indentPendingList.filter(e => e.checked == true);
    console.log(this.checkedPendingList);    
    this.showtableIndent = true;
    $('#myModal').modal('hide');
  }

  loadDataTable(table_data: any, table_name: string, className: string = ".dataTable") {
    if (table_data.length > 0) {
      $(className).DataTable().destroy();
      $(className).DataTable({ destroy: true, searching: false });
    }
    // setTimeout(() => { $(table_name).DataTable({ dom: 'Bfrtip', buttons: ['excel'] }); });
    setTimeout(() => { $(table_name).DataTable(); });
  }
}
