import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-quotation-entry',
  templateUrl: './quotation-entry.component.html',
  styleUrls: ['./quotation-entry.component.css']
})
export class QuotationEntryComponent  extends BaseComponent implements OnInit {
  entryList:Array<any>=[];
  
  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }

  ngOnInit(): void {
  }
  add(){
    this.route.navigate(['home/quotationentry/add']);
  }

}
