import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-manufacturer-master',
  templateUrl: './manufacturer-master.component.html',
  styleUrls: ['./manufacturer-master.component.css']
})
export class ManufacturerMasterComponent implements OnInit {
  mmLst: Array<any> = [];
  cLst: Array<any> = [];
  sLst: Array<any> = [];
  dLst: Array<any> = [];
  tLst: Array<any> = [];
  myForm: FormGroup;
  Address: FormGroup;
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')

  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    this.getCountries();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      manufacturer_id: [0],
      name: ['', Validators.required],
      abbreviation: [''],
      address_id: [0],
      contact_person: [''],
      contact_person_mobileno: [''],
      status: [true],
      created_by: [0],
      modified_by: [0],
      tnt_code: [],
      Address: this.fb.group({
        address_id: [0],
        address_1: [''],
        country_id: ['', Validators.required],
        state_id: ['', Validators.required],
        district_id: ['', Validators.required],
        city_id: ['', Validators.required],
        area_id: ['', Validators.required],
        zip: ['', Validators.required],
        created_by: [''],
        status: [true],
        tnt_code: [''],
      })
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`Manufacturer/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.mmLst = [];
      setTimeout(() => {
        this.mmLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  getCountries() {
    this.CommonService.getCall(`Manufacturer/GetLocation/country/1`).subscribe((res: any) => {
      this.cLst = res.data;

    })
  }

  getStates(id: any) {
    if (id) {
      this.CommonService.getCall(`Manufacturer/GetLocation/state/${id}`).subscribe((res: any) => {
        this.sLst = res.data;
      })
    } else {
      this.sLst = [];
      this.myForm.get('Address.state_id').setValue('');
      this.dLst = [];
      this.myForm.get('Address.district_id').setValue('');
      this.tLst = [];
      this.myForm.get('Address.city_id').setValue('');
    }
  }

  getDistricts(id: any) {
    if (id) {
      this.CommonService.getCall(`Manufacturer/GetLocation/district/${id}`).subscribe((res: any) => {
        this.dLst = res.data;
      })
    } else {
      this.dLst = [];
      this.myForm.get('Address.district_id').setValue('');
      this.tLst = [];
      this.myForm.get('Address.city_id').setValue('');
    }
  }

  getTowns(id: any) {
    if (id) {
      this.CommonService.getCall(`Manufacturer/GetLocation/city/${id}`).subscribe((res: any) => {
        this.tLst = res.data;
      })
    } else {
      this.tLst = [];
      this.myForm.get('Address.city_id').setValue('');
    }
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "manufacturer_id": 0,
      "name": value.name,
      "abbreviation": value.abbreviation,
      "contact_person": value.contact_person,
      "contact_person_mobileno": value.contact_person_mobileno,
      "status": value.status,
      "created_by": +this.userId,
      "modified_by": 0,
      "tnt_code": +this.tenantCode,
      "address_id": 0,
      "Address": {
        "address_id": 0,
        "address_1": value.Address?.address_1,
        "country_id": +value.Address?.country_id,
        "state_id": +value.Address?.state_id,
        "district_id": +value.Address?.district_id,
        "city_id": +value.Address?.city_id,
        "area_id": value.Address?.area_id,
        "zip": value.Address?.zip,
        "status": value.Address?.status,
        "tnt_code": +this.tenantCode,
        "created_by": +this.userId,
        "modified_by": 0,
      }
    }
    if (this.isEdit) {
      payload.manufacturer_id = this.editData.manufacturer_id;
      payload.Address.address_id = this.editData.Address?.address_id;
      payload.modified_by = +this.userId;
      payload.Address.modified_by = +this.userId;
      payload.address_id = +this.editData.address_id;
      this.CommonService.postCall('Manufacturer/Update', payload).subscribe((res: any) => {
        this.toastr.success("Manufacturer Updated Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Manufacturer Not Updated')
      })
    } else {
      this.CommonService.postCall('Manufacturer/Create', payload).subscribe((res: any) => {
        if (res.status == false) {
          this.toastr.warning(res.message, "Manufacturer");
          return;
        }
        this.toastr.success("Manufacturer Created Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Manufacturer Not created')
      })
    }
  }

  edit(data: any) {
    this.isEdit = true;
    this.CommonService.getCall(`Manufacturer/GetById/${data?.manufacturer_id}`).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.datatransform()
      } else {
        this.editData = res.data;
        this.myForm.patchValue({
          status: this.editData.status,
          name: this.editData.name,
          abbreviation: this.editData.abbreviation,
          contact_person: this.editData.contact_person,
          contact_person_mobileno: this.editData.contact_person_mobileno,
          Address: {
            address1: this.editData.Address?.address1,
            status: this.editData.Address?.status,
            zip: this.editData.Address?.zip,
            area_id: this.editData.Address?.area_id,
            country_id: this.editData.Address?.country_id,
          }
        })
        this.getStates(this.editData.Address?.country_id);
        this.getDistricts(this.editData.Address?.state_id);
        this.getTowns(this.editData.Address?.district_id);
        setTimeout(() => {
          this.myForm.patchValue({
            Address: {
              state_id: this.editData.Address?.state_id,
              district_id: this.editData.Address?.district_id,
              city_id: this.editData.Address?.city_id,
            }
          });
        }, 100);
        // this.datatransform()
      }
    }, err => { }
    )
  }

  datatransform() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value = this.editData[key];
      if (value != undefined) control.setValue(value);
    });
  }

  add() {
    this.formInit();
    this.editData = {};
    this.isEdit = false;
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
  }
}
