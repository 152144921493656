import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-add-material-master',
  templateUrl: './add-material-master.component.html',
  styleUrls: ['./add-material-master.component.css']
})
export class AddMaterialMasterComponent implements OnInit {
  myForm: FormGroup;
  case: number = 1;
  token: string;
  childs: any = {}
  roleId = localStorage.getItem('RoleId')
  isTabsDisabled: boolean = (sessionStorage.isTabsDisabled || 'true') == 'true';
  workItemId: any;
  rId: any;
  // isEdit: Boolean = false;
  id: any;
  isEdit: any;
  idEdit: any = {}

  constructor(public CommonService: CommonService, private fb: FormBuilder, public toastr: ToastrService, private active: ActivatedRoute) {

    active.queryParams.subscribe((res) => {
      // if (res.mId) {
        this.id = res.id;
        //  this.isEdit = res.isEdit;
      }
    // }
    )

    //  this.idEdit = { id: this.isEdit }
  }
  ngOnDestroy(): void {


  }

  ngOnInit(): void {

  }


  close() {

    document.getElementById('md_close').click();
  }

}
