import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-request-for-quotation',
  templateUrl: './add-request-for-quotation.component.html',
  styleUrls: ['./add-request-for-quotation.component.css']
})
export class AddRequestForQuotationComponent implements OnInit {
  table: Array<any> = [];

  constructor() { }

  ngOnInit(): void {
  }
  
  Save(){

  }
  close(){
    window.history.back();

  }

}
