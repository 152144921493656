import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-generic-details',
  templateUrl: './generic-details.component.html',
  styleUrls: ['./generic-details.component.css']
})
export class GenericDetailsComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  TenantCode = localStorage.TenantCode;
  userId = localStorage.UserId;
  unitList: Array<any> = [];
  consumeList: Array<any> = [];
  matGpList: Array<any> = [];
  matSubGpList: Array<any> = [];
  mformList: Array<any> = [];
  manuFactList: Array<any> = [];
  editData: Array<any> = [];
  isEdit: any;
  submitted: Boolean = false;
  @Input() id: any;
  constructor(private fb: FormBuilder, public CommonService: CommonService,public toastr: ToastrService, ) {

    super(CommonService, toastr);

  }

  ngOnInit(): void {

    this.getAll();
    this.formInit();
    if (this.id > 0) {
      this.edit();
    }
  }

  formInit() {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      maximum_leve: [''],
      minimum_level: [''],
      abbreviation: ['', Validators.required],
      record_level: [''],
      roq: [''],
      purchase_unit_id: ['', Validators.required],
      sale_unit_id: ['', Validators.required],
      lead_time: [''],
      material_group_id: ['', Validators.required],
      material_subgroup_id: ['', Validators.required],
      material_form_id: ['', Validators.required],
      manufacturer_id: ['', Validators.required],
      purchase_price: [''],
      cost_price: [''],
      sale_price: [''],
      status: [''],
      chargable: [],
      maintain_batch_item: [],
      ved_classification: [],
      item_type: [],
      created_by: localStorage.getItem('UserId'),
      tnt_code: localStorage.getItem('TenantCode')


    })
  }

  getAll() {
    const { TenantCode } = localStorage;
    let purchaseUnit = this.CommonService.getCall(`UnitMaster/GetAllByActive/${TenantCode}/1`);
    let cellUnit = this.CommonService.getCall(`UnitMaster/GetAllByActive/${TenantCode}/1`);
    let matGp = this.CommonService.getCall(`MaterialGroup/GetAllByActive/${TenantCode}/1`);
    let matSubGp = this.CommonService.getCall(`MaterialSubGroup/GetAllByActive/${TenantCode}/1`);
    let mform = this.CommonService.getCall(`MaterialForm/GetAllByActive/${TenantCode}/1`);
    let manuFact = this.CommonService.getCall(`Manufacturer/GetAllByActive/${TenantCode}/1`);

    forkJoin([purchaseUnit, cellUnit, matGp, matSubGp, mform, manuFact]).subscribe((res: any) => {
      console.log(res);
      [this.unitList, this.consumeList, this.matGpList, this.matSubGpList, this.mformList, this.manuFactList] = res.map(e => e.data);
    })

  }



  close() {
    window.history.back();
  }

  get f() { return this.myForm.controls; }
  Save(form: FormGroup) {
    this.submitted = true;
    if (form.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    let value: any = form.value;
    //  let payLoad = this.myForm.getRawValue();
    let payLoad = {
      ...this.myForm.value,
      // ...payLoad
      maximum_leve: parseInt(value.maximum_leve),
      minimum_level: parseInt(value.minimum_level),
      record_level: parseInt(value.record_level),
      roq: parseInt(value.roq),
      purchase_unit_id: parseInt(value.purchase_unit_id),
      sale_unit_id: parseInt(value.sale_unit_id),
      lead_time: parseInt(value.lead_time),
      material_group_id: parseInt(value.material_group_id),
      material_subgroup_id: parseInt(value.material_subgroup_id),
      material_form_id: parseInt(value.material_form_id),
      manufacturer_id: parseInt(value.manufacturer_id),
      purchase_price: parseInt(value.purchase_price),
      cost_price: parseInt(value.cost_price),
      sale_price: parseInt(value.sale_price),
      created_by: +localStorage.getItem('UserId'),
      tnt_code: +localStorage.getItem('TenantCode'),
      chargable: value.chargable.toString(),
      maintain_batch_item: value.maintain_batch_item.toString(),
      ved_classification: value.ved_classification.toString(),
      item_type: value.item_type.toString()
    }
    if (this.id > 0) {
      this.CommonService.postCall('MaterialMaster/Update', payLoad).subscribe(() => {
        window.history.back();
        this.toastr.success(`MaterialMaster updated successfully`);
      }, err => {
        this.toastr.error(err.error ? err.error : 'MaterialMaster Not Updated');
      })
    } else {
      this.CommonService.postCall('MaterialMaster/Create', payLoad).subscribe(res => {
        window.history.back();
        this.toastr.success(`MaterialMaster saved successfully`);
      },
        err => {

          this.toastr.error(err.error ? err.error : `MaterialMaster not created`);
        }
      );
    }



  }


  restrictZero(event: any) {
    if (event.target.value.length == 0 && event.key == '0')
      event.preventDefault();
  }

  edit() {
    const { TenantCode } = localStorage;
    this.CommonService.getCall(`MaterialMaster/GetAll/${TenantCode}`).subscribe((res: any) => {
      // this.editData = res.data[0];
      this.editData = res.data.find(e => e.material_id == this.id);
      this.dataTransfer();
    }, err => { });
  }

  dataTransfer() {
    // let controls = this.myForm.controls;
    
    setTimeout(() => {
      this.myForm.patchValue({
         ...this.editData,
        chargable: this.editData['chargable'],
        maintain_batch_item: this.editData['maintain_batch_item'],
        ved_classification: this.editData['ved_classification'],
        item_type: this.editData['item_type'],
      });
    }, 1000);
  }

}


