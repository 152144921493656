import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare let $: any;
@Component({
  selector: 'app-indent-returns',
  templateUrl: './indent-returns.component.html',
  styleUrls: ['./indent-returns.component.css']
})
export class IndentReturnsComponent implements OnInit {
  strLst: Array<any> = [];
  myForm: FormGroup;
  myForm1: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')
  showtable: boolean = true;

  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      from_date: [((new Date()).toISOString().substring(0, 10))],
      to_date: [((new Date()).toISOString().substring(0, 10))],
      MATERIALGROUP_ID: ['', Validators.required],
    });
    this.myForm1 = this.fb.group({
      date: [((new Date()).toISOString().substring(0, 10))],
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  
  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`StoreMaster/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.strLst = [];
      setTimeout(() => {
        this.strLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "store_id": 0,
      "code": value.code,
      "name": value.name,
      "store_type": +value.store_type,
      "can_raise_po": value.can_raise_po,
      "status": value.status,
      "org_id": 0,
      "created_by": +this.userId,
      "tnt_code": +this.tenantCode,
      "modified_by": 0
    }
    if (this.isEdit) {
      payload.store_id = this.editData.store_id;
      payload.modified_by = +this.userId;
      payload.org_id = +this.editData.org_id
      this.CommonService.postCall('StoreMaster/Update', payload).subscribe((res: any) => {
        this.toastr.success("StoreMaster Updated Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'StoreMaster Not Updated')
      })
    } else {
      this.CommonService.postCall('StoreMaster/Create', payload).subscribe((res: any) => {
        if (res.status == false) {
          this.toastr.warning(res.message, "Store Master");
          return;
        }
        this.toastr.success("StoreMaster Created Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'StoreMaster Not created')
      })
    }
  }

  edit(data: any) {
    this.isEdit = true;
    this.CommonService.getCall(`StoreMaster/GetById/${data?.store_id}`).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.datatransform()
      } else {
        this.editData = res.data;
        this.datatransform()
      }
    }, err => { }
    )
  }

  datatransform() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value = this.editData[key];
      if (value != undefined) control.setValue(value);
    });
  }

  add() {
    this.formInit();
    this.editData = {};
    this.isEdit = false;
    this.showtable = false;
  }

  close() {
    this.showtable = true;
    this.isEdit = false;
    this.myForm.reset();
  }

  closeModal() {
    this.showtable = false;
  }

  cancel() {
    this.router.navigate(['/home/dashboard']);
  }

  onPopup() {
    $('#myModal').modal('show');
  }

  ok() {
    $('#myModal').modal('hide');
  }

  addMaterail() {

  }

  loadDataTable(table_data: any, table_name: string, className: string = ".dataTable") {
    if (table_data.length > 0) {
      $(className).DataTable().destroy();
      $(className).DataTable({ destroy: true, searching: false });
    }
    // setTimeout(() => { $(table_name).DataTable({ dom: 'Bfrtip', buttons: ['excel'] }); });
    setTimeout(() => { $(table_name).DataTable(); });
  }
}
