import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-quotation-comparison',
  templateUrl: './quotation-comparison.component.html',
  styleUrls: ['./quotation-comparison.component.css']
})
export class QuotationComparisonComponent extends BaseComponent  implements  OnInit {

  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }
  ngOnInit(): void {
  }

  Save(form: FormGroup){

  }

}
