import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-price-master',
  templateUrl: './price-master.component.html',
  styleUrls: ['./price-master.component.css']
})
export class PriceMasterComponent implements OnInit {

  myForm!: FormGroup;
  isEdit: boolean = false;
  getListData: Array<any> = [];
  groups: Array<any> = [];
  dropdownItemsList: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  foodPriceId: any;
  editResponse: any = {};
  constructor(private CommonService: CommonService, private active: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder) {
    this.active.params.subscribe((res: any) => {
      this.workItemId = res.id;
    })
    this.loadlabels();
    this.loadHeadings();
  }


  ngOnInit(): void {
    this.myForm = this.fb.group({
      foodgroup_id: ['', Validators.required],
      fooditem_id: ['', Validators.required],
      foodprice_general: ['', Validators.required],
      foodprice_student: ['', Validators.required],
      foodprice_staff: ['', Validators.required],
      foodprice_discount:[''],
      foofprice_effect_from: ['', Validators.required],
      foodprice_effect_to: ['', Validators.required],
      foodprice_status: ['',Validators.required],
    })
    this.priceGetList();
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  priceGetList() {
    let payload = { 'tnt_code': localStorage.getItem('TenantCode') }
    this.getListData = [];
    this.CommonService.postCall('FoodPrice/GetList', payload).subscribe((response: any) => { this.getListData = response; })
  }

  foodGroups() {
    this.groups = [];
    let payload = { "tnt_code": localStorage.getItem('TenantCode') }
    this.CommonService.postCall('FoodGroup/GetList', payload).subscribe((res: any) => { this.groups = res; })
  }

  changeFoodGroup(foodGroupId) {
    this.dropdownItemsList = [];
    this.CommonService.postCall(`FoodItem/GetListItem`, { foodgroup_id: foodGroupId }).subscribe((res: any) => {
      this.dropdownItemsList = res;
    })
  }

  onSubmit(data: FormGroup) {
    let payload = data.value;
    payload.tnt_code = localStorage.getItem('TenantCode');
    payload.foodprice_general = payload.foodprice_general 
    if (this.isEdit) {
      payload['foodprice_modifiedby'] = localStorage.getItem('UserId');
      payload['foodprice_id'] = this.foodPriceId;
      payload.foodprice_modifieddate = new Date();
      this.CommonService.postCall('FoodPrice/Update', payload).subscribe((response: any) => {
        this.priceGetList();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close').click();
      }, err => { err.error ? err.error : this.toastr.error("Unsuccessfull!") })
    } else {
      payload.foodprice_createdby = localStorage.getItem('UserId');
      payload.foodprice_createddate = new Date();
      this.CommonService.postCall('FoodPrice/Create', payload).subscribe((response: any) => {
        this.priceGetList();
        this.toastr.success("Created Successfully");
        document.getElementById('md_close').click();
      }, err => { err.error ? err.error : this.toastr.error("Unsuccessfull!") })
    }
  }
  edit(OrderId: any) {
    this.isEdit = true;
    this.foodPriceId = OrderId;
    let payload = { 'foodprice_id': this.foodPriceId }
    this.CommonService.postCall('FoodPrice/Get', payload).subscribe((getResponse: any) => {
      if (getResponse instanceof Array) {
        this.editResponse = getResponse[0]
      } else if (getResponse instanceof Object) {
        this.editResponse = getResponse;
      }
      this.setValues();
      this.foodGroups();
      this.changeFoodGroup(getResponse.foodgroup_id)
    })
  }
  setValues() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      control.setValue(this.editResponse[key])
    });
    ctrls['foofprice_effect_from'].setValue(moment(this.editResponse.foofprice_effect_from).format('yyyy-MM-DD'));
    ctrls['foodprice_effect_to'].setValue(moment(this.editResponse.foodprice_effect_to).format('yyyy-MM-DD'));
  }
  add() {
    this.editResponse = {};
    this.isEdit = false;
    this.myForm.reset();
    this.foodGroups();
  }
  close() { }
}
