import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base.component';


@Component({
  selector: 'app-book-allocation-view-report',
  templateUrl: './book-allocation-view-report.component.html',
  styleUrls: ['./book-allocation-view-report.component.css']
})
export class BookAllocationViewReportComponent extends BaseComponent implements OnInit {
  bookAllocation: Array<any> = [];
  titleName: string;
  titleId: string;
  bookCode: string;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private commonService: CommonService, private fb: FormBuilder, private active: ActivatedRoute, private route: Router, toastr: ToastrService) {
    super(commonService, toastr);

    active.queryParams.subscribe((res) => {
      if (res.titleId && res.titleName) {
        this.titleName = res.titleName;
        this.titleId = res.titleId;
        this.bookCode = res.bookCode;
        this.workItemId = res.wId;

      }
    })
  }

  ngOnInit(): void {
    this.getViewReport()
    this.loadReportDtOptions();
    this.loadlabels();
    this.loadHeadings();
  }

  activeSpinner() {
    this.commonService.activateSpinner();
  }

  deactivateSpinner() {
    this.commonService.deactivateSpinner()
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadReportDtOptions() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: "excel",
          filename: 'Books Allocation View Report',
        }
      ],
      order: []
    }
  }

  getViewReport() {

    let payLoad = {
      "BOOK_ID": this.titleId
    }
    this.commonService.postCall("LibraryManagement/BooksAllocationReport", payLoad).subscribe((res: any) => {
      this.bookAllocation = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    });
  }


  ok() {
    this.activeSpinner();
    this.route.navigate([`/home/bookAllocation/${this.workItemId}`]
    );
    this.deactivateSpinner();
  }

}
