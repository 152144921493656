<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.BarCode}}</h5>
                                    </div>
                                    <div class="card pt-4">
                                        <div style="text-align: center; margin-top: 0px; margin-bottom: 0px;"
                                            id="barCode">
                                            <!-- <img alt='Barcode Generator TEC-IT' [src]="barcode"
                                   /> -->
                                            <img src="https://barcode.tec-it.com/barcode.ashx?data={{barCode}}&code=Code128&translate-esc=on"
                                                alt="Barcode Generator TEC-IT">
                                            <!-- <img
                                    src="../../../assets/img/bar.jpg" alt=""> -->
                                        </div>
                                    </div>
                                    <div class="text-center pt-4 mt-4">
                                        <button type="button" class="btn btn-primary" (click)="Print()">
                                            Print</button> &nbsp;
                                        <button type="button" class="btn btn-danger" (click)="back()">
                                            Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>