<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">
                                            Material Sub Group</h5>
                                    </div>

                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="materialsubLst.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Material Sub Group Name </th>
                                                        <th>
                                                            Material Group Name
                                                        </th>
                                                        <th>Stock Account Code</th>
                                                        <th>Stock Consumption Account</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of materialsubLst">
                                                        <td>{{item.NAME}}</td>
                                                        <td>{{getGrpName(item.MATERIALGROUP_ID)}}</td>
                                                        <td>{{item.STOCK_ACCOUNT_CODE}}</td>
                                                        <td>{{item.ASSET_ACCOUNT_CODE}}</td>
                                                        <td>{{item.STATUS==1?"Active":"Inactive"}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">
                    Material Sub Group</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100">
                    Material Sub Group</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Material Group Name<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='MATERIALGROUP_ID'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="">Select</option>
                                        <option *ngFor='let item of grpLst' [value]="item.materialgroup_id">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('MATERIALGROUP_ID').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('MATERIALGROUP_ID').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Material Sub Group Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" placeholder="" maxlength="50" class="form-control"
                                        formControlName="NAME"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('NAME').touched">
                                    <span class="text-danger" *ngIf="myForm.get('NAME').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Stock Account Code </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text"
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="6"
                                        placeholder="" class="form-control" formControlName="STOCK_ACCOUNT_CODE">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Stock Consumption Account </label>
                            <div class="col-md-8 inputGroupContainer">
                                <input type="text" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    maxlength="6" placeholder="" class="form-control"
                                    formControlName="ASSET_ACCOUNT_CODE">
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Status </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="radio" name="STATUS" id="internal" [value]=1 checked
                                        formControlName="STATUS">
                                    &nbsp;<label class="form-check-label" for="internal">Active</label>

                                    &nbsp;&nbsp;
                                    <input type="radio" name="STATUS" id="external" [value]=0
                                        formControlName="STATUS">&nbsp;<label class="form-check-label"
                                        for="external">Inactive</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>