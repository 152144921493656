<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">
                                            Supplier Master</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" (click)="add()"
                                                data-bs-toggle="modal" data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="smLst.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>
                                                            Supplier Code </th> -->
                                                        <th>Supplier Name</th>
                                                        <!-- <th>Drug Licence Number</th> -->
                                                        <th>Tax Number</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of smLst">
                                                        <!-- <td>{{item.code}}</td> -->
                                                        <td>{{item.name}}</td>
                                                        <!-- <td>{{item.licence}}</td> -->
                                                        <td>{{item.taxno}}</td>
                                                        <td>{{item.status==true?"Active":"Inactive"}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100"> Supplier Master</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Supplier Master</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Supplier Code </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm">
                                </div>
                            </div>
                        </div> -->
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Supplier Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" formControlName="name"
                                        maxlength="25"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('name').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="well form-horizontal" formGroupName="Address">
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    P.O.Box <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input placeholder="" class="form-control form-select-sm"
                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                            formControlName="zip">
                                    </div>
                                    <div *ngIf="myForm.get('Address.zip').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.zip').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Physical Address</label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input placeholder="" class="form-control form-select-sm"
                                            formControlName="address_1">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Country <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='country_id'
                                            (change)="getStates($any($event.target).value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of cLst' [value]="item.country_id">
                                                {{item.country_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.country_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.country_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    State <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='state_id' (change)="getDistricts($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of sLst' [value]="item.state_id">
                                                {{item.state_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.state_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.state_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    District <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='district_id' (change)="getTowns($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of dLst' [value]="item.district_id">
                                                {{item.district_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.district_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.district_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Town <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='city_id'
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of tLst' [value]="item.city_id">
                                                {{item.city_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.city_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.city_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Area <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <div class="input-group">
                                            <input placeholder="" class="form-control form-select-sm"
                                                formControlName="area_id"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);">
                                        </div>
                                    </div>
                                    <div *ngIf="myForm.get('Address.area_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.area_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Terms & Condition
                                Credit Period <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm"
                                        formControlName="credit_period" maxlength="4"
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                </div>
                                <div *ngIf="myForm.get('credit_period').touched">
                                    <span class="text-danger" *ngIf="myForm.get('credit_period').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Supplier Type <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='supplier_type_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of supplierTypeLst' [value]="item.suppliertype_id">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('supplier_type_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('supplier_type_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Drug Licence Number</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder=""
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        class="form-control form-select-sm">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Tax Number</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" formControlName="taxno"
                                        maxlength="10"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Supplier Classification<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='supplier_classification_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of supplierClassLst'
                                            [value]="item.supp_clasification_id">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('supplier_classification_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('supplier_classification_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Contact Person Name</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" maxlength="25"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);"
                                        formControlName="contact_person">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Contact Person Phone</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" maxlength="10"
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        formControlName="contact_person_mobile">
                                </div>
                            </div>
                        </div>
                        <div class="well form-horizontal" formGroupName="Address">
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Status </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="radio" name="status" id="internal" [value]=true checked
                                            formControlName="status">
                                        &nbsp;<label class="form-check-label" for="internal">Active</label>

                                        &nbsp;&nbsp;
                                        <input type="radio" name="status" id="external" [value]=false
                                            formControlName="status">&nbsp;<label class="form-check-label"
                                            for="external">Inactive</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Cheque in favour of</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" maxlength="50"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);"
                                        formControlName="cheque_favour">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Agreement Term</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        formControlName='agreement_term'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Payment Term</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        formControlName='payment_term'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Delivery Term</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        formControlName='delivery_term'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                </textarea>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>