import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-add-purchase-request',
  templateUrl: './add-purchase-request.component.html',
  styleUrls: ['./add-purchase-request.component.css']
})
export class AddPurchaseRequestComponent extends BaseComponent implements OnInit {
  table: Array<any> = [] ;
  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);
  }

  ngOnInit(): void {
  }
  add(){
    this.route.navigate(['home/addindent']);
  }
  Save(){

  }
  close(){
    window.history.back();
  }
}
