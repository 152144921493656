import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit {
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  isEdit: boolean = null;
  myForm: FormGroup;
  enableType: boolean = false;
  enableItem: boolean = false;
  editData: any;
  groups: Array<any> = [];
  list: Array<any> = [];
  foodGroup: any;
  foodItem: any;
  quantity: number = 1;
  price: number = 0;
  dropdownItemsList: Array<any> = [];
  showTable: boolean = true;
  table: Array<any> = [];
  orderTypeList: Array<any> = [];
  mealType = [{ id: 1, name: 'Breakfast' }, { id: 2, name: 'Lunch' }, { id: 3, name: 'Dinner' }];
  totalAmt: Number = 0;
  foodGroupId: any;
  minDate: any = moment().format('yyyy-MM-DD')
  viewData: Array<any> = [];
  foodItems: any = {};
  mainDetails: Array<any> = [];
  dataValue: any = {};
  totalAmont: any;
  lists: Array<any> = [];
  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadHeadings();
    this.loadlabels();
    this.foodGroups();
    this.load();
    this.getorderTypeList();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      ordertran_date: [''],
      ordertran_typeoforder: [''],
      ordertran_meal_type: [''],
      foodgroup_id: [''],
      fooditem_id: [''],
      ordertran_paymentmode: [''],
      price_cat: [''],

    })
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  load() {
    // this.activiceSpinner();
    this.CommonService.postCall(`FoodOrderTransaction/GetList`, { tnt_code: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }


  add(form: FormGroup) {
    let value = form.value
    let selectedFoodGroup = this.lists.find(e => e.foodgroup_id == value.foodgroup_id)?.foodgroup_id;
    let selectedFoodItem = this.lists.find(e => e.fooditem_id == value.fooditem_id)?.fooditem_id;
    if (selectedFoodGroup == value.foodgroup_id && selectedFoodItem == value.fooditem_id) {
      return this.toastr.warning('Food Group and Food Item Already Exists');
    }

    let payload = {
      "price_cat": value.price_cat,
      "foodgroup_id": value.foodgroup_id,
      "fooditem_id": value.fooditem_id
    }
    this.CommonService.postCall('FoodOrderTransaction/FoodPricebyCategory', payload).subscribe((res: any) => {
      if (res == null) {
        return this.toastr.warning('Add price for Food Item');
      }
      this.list = res;
      let obj = {
        "foodgroup_name": res.foodgroup_name,
        "fooditem_name": res.fooditem_name,
        "ordertran_price": res.priceList,
        "foodgroup_id": value.foodgroup_id,
        "fooditem_id": value.fooditem_id,
        "price_cat": res.price_cat,
        "unitprice": res.priceList,
        "ordertran_qty": 1
      }
      this.lists.push(obj)
      this.myForm.controls['foodgroup_id'].setValue('');
      this.myForm.controls['fooditem_id'].setValue('');
      // this.lists.push(this.quantity)
    })
  }


  pluse(opp: string, index: any) {
    const indexVal = this.lists[index];
    // const price = indexVal.priceList;
    if (indexVal.quantity <= 0) {
      this.delete(index);
    }
    if (opp == "plus") {
      indexVal.ordertran_qty = indexVal.ordertran_qty + 1;
      indexVal.ordertran_price = indexVal.ordertran_qty * indexVal.unitprice;
    } else {
      indexVal.ordertran_qty = indexVal.ordertran_qty - 1;
      indexVal.ordertran_price = indexVal.ordertran_price - indexVal.unitprice;
    }
  }

  delete(index: any) {
    this.lists.splice(index, 1);
  }

  changeType(orderType: any) {
    if (orderType == '1') {
      this.enableType = true;
      this.enableItem = false;
    } else {
      this.enableType = false;
      this.enableItem = true;
    }
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getorderTypeList() {
    this.orderTypeList = [];
    this.CommonService.postCall('FoodOrder/GetList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((response: any) => {
      setTimeout(() => {
        this.orderTypeList = response;
      }, 10);
    })
  }

  foodGroups() {
    this.groups = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('FoodGroup/GetList', payload).subscribe((res: any) => {
      this.groups = res;
    })
  }

  changeFoodGroup(foodGroupId) {
    this.foodGroupId = foodGroupId;
    this.dropdownItemsList = [];
    this.CommonService.postCall(`FoodItem/GetListItem`, { foodgroup_id: this.foodGroupId }).subscribe((res: any) => {
      this.dropdownItemsList = res;
    })
  }

  changeFoodItem(foodItemId) {
    this.price = 0;
    this.CommonService.postCall(`FoodOrderTransaction/Foodprice`, { foodgroup_id: this.foodGroupId, fooditem_id: foodItemId }).subscribe((res: any) => {
      this.price = res.foodprice_general
    })
  }


  onSubmit(form: FormGroup) {
    if (this.lists.length <= 0) {
      return this.toastr.warning('Please add atleast one food item');
    }
    let value: any = form.value;
    this.totalAmt = 0;
    this.lists.forEach(e => {
      this.totalAmt += e.ordertran_price;
    });
    let payload = {
      "ordertran_date": value.ordertran_date,
      "list": this.lists,
      "ordertran_totalamt": this.totalAmt,
      "ordertran_paidamt": 0,
      "ordertran_discount": 0,
      "ordertran_paymentmode": value.ordertran_paymentmode,
      "ordertran_typeoforder": value.ordertran_typeoforder,
      "ordertran_meal_type": +value.ordertran_meal_type,
      "ordertran_status ": "true",
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    // if (this.isEdit) {
    //   payload['ordertran_modifiedby'] = localStorage.getItem('UserId');
    //   payload['ordertran_modifieddate'] = new Date();
    //   payload['ordertran_tokenid'] = this.editData.dms_id;
    //   this.CommonService.postCall('FoodOrderTransaction/Update', payload).subscribe((res: any) => {
    //     this.load();
    //     this.toastr.success("Place Order Updated Successfully");
    //     document.getElementById('md_close').click();
    //   }, err => {
    //     this.toastr.error(err.error ? err.error : 'Academic Not Updated')
    //   })
    // } else {
    payload['ordertran_createdby'] = localStorage.getItem('UserId');
    payload['ordertran_createddate'] = new Date();
    this.CommonService.postCall('FoodOrderTransaction/Create', payload).subscribe((res: any) => {
      $("#myModal").modal('show');
      this.dataValue = res;
      this.totalAmont = res.ordertran_totalamt;
      this.viewData = res.list;

      //  this.foodItems = res.list;
      //  this.mainDetails.push(this.viewData)


      this.load();
      this.toastr.success("Place Order Created Successfully");
      // document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error.message ? err.error.message : 'Already Exist')

    })
  }


  // }


  edit(oId: any) {
    this.isEdit = true;
    this.showTable = false;
    this.myForm.reset();
    let payLoad = {
      "ordertran_id": oId
    }
    this.CommonService.postCall('FoodOrderTransaction/Get', payLoad).subscribe((res: any) => {

      this.showTable = false;
      if (res.ordertran_typeoforder == 1) {
        this.enableType = true;
        this.enableItem = false;
      } else {
        this.enableType = false;
        this.enableItem = true;
      }
      this.list = res.list
      setTimeout(() => {
        this.changeFoodGroup(res.foodgroup_id)
      }, 100);
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }


  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
    ctrls['ordertran_date'].setValue(moment(this.editData['ordertran_date']).format('yyyy-MM-DD'));
    this.myForm.patchValue({

    })
  }

  addData() {

    this.showTable = false;

  }
  close() {
    this.showTable = true;
    this.enableType = false;
    this.enableItem = false;
    this.myForm.reset();
    this.lists = []
  }

  Print() {
    var printContents: any = document.getElementById("print")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
  cancel() {
    this.showTable = true;
    this.enableType = false;
    this.enableItem = false;
    this.myForm.reset();
    this.lists = []
  }
}
