<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add Quotation Entry</h5>
                                       
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">Quotation Entry Number</label>
                                                <div class="input-group">
                                                    <input type="text"  class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">Quotation Number</label><span
                                                    class="text-danger">*</span>
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    
                                                </select>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">Supplier</label><span
                                                    class="text-danger">*</span>
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    
                                                </select>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">Quotation Entry Date</label>
                                                <div class="input-group">
                                                    <input type="date"  class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">Quotation  Date</label>
                                                <div class="input-group">
                                                    <input type="date"  class="form-control form-select-sm">
                                                </div>
                                            </div>

                                            <div  class="table-responsive pt-4">
                                                <table id="patreggraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            <th> Material Name </th>
                                                            <th> Manufacturer Name </th>
                                                            <th> Purchase Unit </th>
                                                            <th> Required Qty </th>
                                                            <th> Can Supply</th>
                                                            <th> Rate</th>
                                                            <th> Free Qty</th>
                                                            <th> Discount(%)</th>
                                                            <th> Tax(%)</th>
                                                            <th> Purchace Price</th>
                                                            <th> Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                           
                                            <div class="col-md-3">
                                                <label for="sel1">Terms</label>
                                                <div class="input-group">
                                                    <textarea placeholder="" class="form-control"
                                                        formControlName=""></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <label for="sel1">Conditions</label>
                                                <div class="input-group">
                                                    <textarea placeholder="" class="form-control"
                                                        formControlName=""></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="sel1">
                                                    Credit Period (Days) </label>
                                                <div class="input-group">
                                                    <input type="text"  class="form-control form-select-sm">
                                                </div>
                                            </div>
                                           

                                            <div class="footer mt-5 mb-4 text-center">
                                                <button type="button" class="btn btn-outline-success btn-sm" 
                                                    (click)="Save()">Save</button>&nbsp;
                                                
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()" id="md_close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>