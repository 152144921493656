import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare let $: any;

@Component({
  selector: 'app-grn',
  templateUrl: './grn.component.html',
  styleUrls: ['./grn.component.css']
})
export class GrnComponent implements OnInit {
  myForm: FormGroup;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
  }


  onSubmit(form: any) { }

  add() {
    this.route.navigate(['home/grn/add']);
  }

  close(){}
}
