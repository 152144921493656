import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-preferred-supplier',
  templateUrl: './preferred-supplier.component.html',
  styleUrls: ['./preferred-supplier.component.css']
})
export class PreferredSupplierComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  preferredSupplierList: Array<any> = [];
  supplierList: Array<any> = [];
  @Input() id: any;
  dropdownSettings: IDropdownSettings;
  dropdownList = [];
  selectedIds = [];
  tableData = [];
  TenantCode = localStorage.TenantCode;
  UserId = localStorage.UserId;

  constructor(private fb: FormBuilder, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr)
  }

  ngOnInit(): void {
    this.getPrefferedSupplier();
    this.getSupplierList();
    this.formInit();
    console.log(this.id);


  }

  formInit() {
    this.myForm = this.fb.group({
      name: ['']
    })
    this.dropdownList = this.supplierList;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "supplier_id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true

    }
  }


  getPrefferedSupplier() {
    this.preferredSupplierList = [];
    this.CommonService.getCall(`MaterialMaster/GetMaterialSupplierByID/${this.id}`).subscribe((res) => {
      this.preferredSupplierList = res.data;
    });


  }

  getSupplierList() {
    const { TenantCode } = localStorage
    this.supplierList = [];
    this.CommonService.getCall(`SupplierMaster/GetAll/${TenantCode}`).subscribe((res) => {
      this.supplierList = res.data;
    });
  }

  add() {


  }

  cancel() {

  }

  save(form: FormGroup) {

    let value: any = form.value;
    let Payload = [];
    value.name.forEach(element => {
      let obj = {
        material_supp_id: 0,
        material_id: this.id,
        supplier_id: element.supplier_id,
        status: true,
        created_by: +localStorage.getItem('UserId'),
        // modified_by: +localStorage.getItem('UserId'),
        tnt_code: +localStorage.getItem('TenantCode')
      }
      Payload.push(obj);
    });
   
    this.CommonService.postCall('MaterialMaster/AddSuppliers', Payload).subscribe((res) => {
      this.toastr.success(`Suppliers Created successfully`);
      err => {

        this.toastr.error(err.error ? err.error : `Suppliers not created`);
      }
    })

  }

  onItemSelect(item: any) {
    this.selectedIds.push(item.supplier_id);

  }
  onSelectAll(event: any) {
    this.selectedIds = this.supplierList.map(x => x.supplier_id);

  }
  onDeSelectAll() {
    this.selectedIds = [];
    this.tableData = [...this.supplierList];
  }
  onItemDeSelect(item: any) {
    this.selectedIds.splice(this.selectedIds.indexOf(item.supplier_id), 1);

  }


}
