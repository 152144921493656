<div class="form-group pt-4 ">
    <div class="card-body">
        <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="Save(myForm)">
            <fieldset>
                <div class="row">
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Material Name</label><span class="text-danger">*</span>
                        <div class="input-group">
                            <input type="text" formControlName="name" class="form-control form-select-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Material Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Abbreviation</label><span class="text-danger">*</span>
                        <div class="input-group">
                            <input type="text" formControlName="abbreviation" class="form-control form-select-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.abbreviation.errors }">
                            <div *ngIf="submitted && f.abbreviation.errors" class="invalid-feedback">
                                <div *ngIf="f.abbreviation.errors.required"> Abbreviation is
                                    required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Maximum level</label>
                        <div class="input-group">
                            <input type="text" formControlName="maximum_leve" class="form-control form-select-sm">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Minimum level</label>
                        <div class="input-group">
                            <input type="text" formControlName="minimum_level" class="form-control form-select-sm">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">ROL</label>
                        <div class="input-group">
                            <input type="text" formControlName="record_level" class="form-control form-select-sm">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">ROQ</label>
                        <div class="input-group">
                            <input type="text" formControlName="roq" class="form-control form-select-sm">
                        </div>
                    </div>

                    <div class="col-md-4 pt-4">
                        <label for="sel1">Purchase Unit</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="purchase_unit_id"
                            [ngClass]="{ 'is-invalid': submitted && f.purchase_unit_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let purchase of unitList" [value]='purchase.unit_id'>
                                {{purchase.name}}</option>

                        </select>
                        <div *ngIf="submitted && f.purchase_unit_id.errors" class="invalid-feedback">
                            <div *ngIf="f.purchase_unit_id.errors.required">Purchase Unit is
                                required</div>
                        </div>
                    </div>


                    <div class="col-md-4 pt-4">
                        <label for="sel1">Consumption / Sale Unit</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="sale_unit_id"
                            [ngClass]="{ 'is-invalid': submitted && f.sale_unit_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let consume of consumeList" [value]='consume.unit_id'>
                                {{consume.name}}</option>

                        </select>
                        <div *ngIf="submitted && f.sale_unit_id.errors" class="invalid-feedback">
                            <div *ngIf="f.sale_unit_id.errors.required">Consumption / Sale Unit is
                                required</div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Lead Time (Days)</label>
                        <div class="input-group">
                            <input type="text" formControlName="lead_time" class="form-control form-select-sm"
                                (keydown)="restrictZero($event)"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                        </div>
                    </div>

                    <div class="col-md-4 pt-4">
                        <label for="sel1">Material Group Name</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="material_group_id"
                            [ngClass]="{ 'is-invalid': submitted && f.material_group_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let matgp of matGpList" [value]='matgp.materialgroup_id'>
                                {{matgp.name}}</option>

                        </select>
                        <div *ngIf="submitted && f.material_group_id.errors" class="invalid-feedback">
                            <div *ngIf="f.material_group_id.errors.required">Material Group Name is
                                required</div>
                        </div>
                    </div>

                    <div class="col-md-4 pt-4">
                        <label for="sel1">Material Sub Group Name</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="material_subgroup_id"
                            [ngClass]="{ 'is-invalid': submitted && f.material_subgroup_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let matsubgp of matSubGpList" [value]='matsubgp.MATERIALSUBGROUP_ID'>
                                {{matsubgp.NAME}}</option>

                        </select>
                        <div *ngIf="submitted && f.material_subgroup_id.errors" class="invalid-feedback">
                            <div *ngIf="f.material_subgroup_id.errors.required">Material Sub Group Name is
                                required</div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Material Form</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="material_form_id"
                            [ngClass]="{ 'is-invalid': submitted && f.material_form_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let material of mformList" [value]='material.materialform_id'>
                                {{material.name}}</option>

                        </select>
                        <div *ngIf="submitted && f.material_form_id.errors" class="invalid-feedback">
                            <div *ngIf="f.material_form_id.errors.required">Material Form is
                                required</div>
                        </div>
                    </div>

                    <div class="col-md-4 pt-4">
                        <label for="sel1">Manufacturer Name</label><span class="text-danger">*</span>
                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                            formControlName="manufacturer_id"
                            [ngClass]="{ 'is-invalid': submitted && f.manufacturer_id.errors }">
                            <option value="" [selected]="true">select</option>
                            <option *ngFor="let menu of manuFactList" [value]='menu.manufacturer_id'>
                                {{menu.name}}</option>

                        </select>
                        <div *ngIf="submitted && f.manufacturer_id.errors" class="invalid-feedback">
                            <div *ngIf="f.manufacturer_id.errors.required">
                                Manufacturer Name is required</div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Purchase Price</label>
                        <div class="input-group">
                            <input type="text" formControlName="purchase_price" class="form-control form-select-sm"
                                (keydown)="restrictZero($event)"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Cost Price</label>
                        <div class="input-group">
                            <input type="text" formControlName="cost_price" class="form-control form-select-sm"
                                (keydown)="restrictZero($event)"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4">
                        <label for="sel1">Sale Price</label>
                        <div class="input-group">
                            <input type="text" formControlName="sale_price" class="form-control form-select-sm"
                                (keydown)="restrictZero($event)"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                        </div>
                    </div>

                    <div class="col-xl-4 pt-4">
                        <label for="sel1">Status</label>
                        <div>
                            <input type="radio" formControlName="status" [value]=true>Active <br>
                            <input type="radio" formControlName="status" [value]=false>Inactive
                        </div>
                    </div>
                    <div class="col-xl-4 pt-4">
                        <label for="sel1">Chargable to Patient</label>
                        <div>
                            <input type="radio" name="chargable" formControlName="chargable" value="1">Yes <br>
                            <input type="radio" name="chargable" formControlName="chargable" value="0">No
                        </div>
                        
                    </div>
                    <div class="col-xl-4 pt-4">
                        <label for="sel1">Maintain Batch Item</label>
                        <div>
                            <input type="radio" formControlName="maintain_batch_item" value="1">Yes <br>
                            <input type="radio" formControlName="maintain_batch_item" value="0">No
                        </div>
                    </div>
                    <div class="col-xl-4 pt-4">
                        <label for="sel1">VED Classification</label>
                        <div>
                            <input type="radio" formControlName="ved_classification" value="1">Vital <br>
                            <input type="radio" formControlName="ved_classification" value="2">Essential<br>
                            <input type="radio" formControlName="ved_classification" value="3">Desirable
                        </div>
                    </div>
                    <div class="col-xl-4 pt-4">
                        <label for="sel1">Item Type</label>
                        <div>
                            <input type="radio" formControlName="item_type" value="1">Asset <br>
                            <input type="radio" formControlName="item_type" value="2">Consumable <br>
                            <input type="radio" formControlName="item_type" value="3">Others
                        </div>
                    </div>

                </div>
            </fieldset>
        </form>

        <div class="footer mt-5 mb-4 text-center">
            <button type="button" class="btn btn-outline-success btn-sm" *ngIf="id == 0"
                (click)="Save(myForm)">Save</button>
            &nbsp;
            <button type="button" class="btn btn-outline-success btn-sm" *ngIf="id > 0"
                (click)="Save(myForm)">Update</button>
            &nbsp;
            <button type="button" class="btn btn-sm btn-danger" (click)="close()" id="md_close">cancel</button>
        </div>
    </div>

</div>