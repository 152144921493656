<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Stock Transfer Request</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <div class="row">
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> From Store <span>:</span></label>
                                                    
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> Date <span>:</span></label>
                                                    
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> ransfer Request No <span>:</span></label>
                                                    
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> Center</label>
                                                    <div class="input-group">
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" #grnType>
                                                            <option value="" selected>select</option>
                                                            <option value="Purchase">Purchase</option>
                                                            <option value="Donation">Donation</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> To Store</label>
                                                    <div class="input-group">
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" #grnType>
                                                            <option value="" selected>select</option>
                                                            <option value="Purchase">Purchase</option>
                                                            <option value="Donation">Donation</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> User <span>:</span></label>
                                                    
                                                </div>
                                                


                                                <div class="table-responsive mt-5 mb-5">
                                                    <table id="patreggraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr style="white-space: nowrap;">
                                                                <th> Sl No </th>
                                                                <th> Material Name </th>
                                                                <th> QOH </th>
                                                                <th> Unit </th>
                                                                <th> Requried Quantity </th>
                                                                <th> Requried By Date </th>
                                                                <th> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Approved  By</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" selected>select</option>

                                                    </select>                                                  

                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Approved Date</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm">
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-3">
                                                    <label for="sel1">Remarks</label>
                                                    <div class="input-group">
                                                        <textarea placeholder="" class="form-control"
                                                            formControlName=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="footer mt-5 mb-4 text-center">
                                            <button type="button" class="btn btn-outline-success btn-sm"
                                                (click)="onSubmit(myForm)">Save</button>&nbsp;

                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>