import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.css']
})
export class PurchaseHistoryComponent extends BaseComponent implements OnInit {
  historyList: Array<any> = [];
  constructor(CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr)
   }


  ngOnInit(): void {
  }

}
