import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare let $: any;
@Component({
  selector: 'app-indent-order',
  templateUrl: './indent-order.component.html',
  styleUrls: ['./indent-order.component.css']
})
export class IndentOrderComponent implements OnInit {
  strLst: Array<any> = [];
  indetOrderGetlist: Array<any> = [];
  myForm: FormGroup;
  myForm1: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')
  showtable: boolean = true;
  departmentList: Array<any> = [];
  DeptId: any = '';
  StoreId: any = '';
  meterialList: Array<any> = [];
  indentOrderList: Array<any> = [];
  loadIndentOrder: Array<any> = [];

  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    this.getDepartments();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      fromDate: [((new Date()).toISOString().substring(0, 10))],
      toDate: [((new Date()).toISOString().substring(0, 10))],
      storeId: ['', Validators.required],
      DeptId: ['', Validators.required],
      isssueStatus: [0]
    });
    this.myForm1 = this.fb.group({
      date: [((new Date()).toISOString().substring(0, 10))],
      DeptId: [''],
      storeId: [''],
      indentNo: [0],
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`StoreMaster/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.strLst = [];
      setTimeout(() => {
        this.strLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  loadMaterials() {
    this.activeSpinner();
    this.CommonService.getCall(`MaterialMaster/GetAllByActive/${this.tenantCode}/1`).subscribe((res: any) => {
      this.meterialList = [];
      setTimeout(() => {
        this.meterialList = res.data;
        console.log(this.meterialList);
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  checkAll(event: any) {
    if (event.target.checked == true) {
      this.meterialList.forEach(a => { a.checked = true });
    }
    else {
      this.meterialList.forEach(a => { a.checked = false });
    }
  }

  getDepartments() {
    this.activeSpinner();
    this.CommonService.postCall(`Department/dropdown`, { "TNT_CODE": this.tenantCode }).subscribe((res: any) => {
      this.departmentList = [];
      setTimeout(() => {
        this.departmentList = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  getIndentOrders() {
    let val = this.myForm.getRawValue();
    this.activeSpinner();
    this.CommonService.getCall(`IndentOrder/GetInvIndentOrderBySearch/${val.storeId}/${this.tenantCode}`).subscribe((res: any) => {
      this.loadIndentOrder = [];
      setTimeout(() => {
        this.loadIndentOrder = res.data.map(v => ({ ...v, checked: false, urgent: false, indentQty: 0 }));
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    let payload = {
      "isssueStatus": parseInt(value.isssueStatus),
      "IndentType": 0,
      "fromDate": value.fromDate,
      "toDate": value.toDate,
      "DeptId": parseInt(value.DeptId),
      "storeId": parseInt(value.storeId)
    }
    this.activeSpinner();
    this.CommonService.postCall(`IndentOrder/GetInvIndentOrderByCriteria`, payload).subscribe((res: any) => {
      this.indetOrderGetlist = [];
      if (res.data.length == 0) {
        this.toastr.warning('No data available for selected records');
        this.deactivateSpinner();
        return;
      }
      setTimeout(() => {
        this.indetOrderGetlist = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }


  onSubmit1(form: FormGroup) {
    let value: any = form.value;
    let payArr = [];
    this.indentOrderList.forEach(e => {
      let obj = {
        "indentorderdtl_id": 0,
        "indentorder_id": 0,
        "material_id": e.material_id1,
        "status": 1,
        "created_by": this.userId,
        "modified_by": this.userId,
        "modified_date": new Date(),
        "urgent_ind": e.urgent == true ? "Y" : "N",
        "indent_qty": +e.indentQty,
        "issued_qty": 0,
        "balance_qty": +e.indentQty,
        "tnt_code":this.tenantCode,
      }
      payArr.push(obj);
    });
    let payload = {
      "indentorder_id": 0,
      "indentorder_no": "",
      "dept_id": +value.DeptId,
      "store_id": +value.storeId,
      "indent_order_date": value.date,
      "status": 1,
      "created_by": this.userId,
      "modified_by": this.userId,
      "modified_date": new Date(),
      "tnt_code": this.tenantCode,
      "IndentOrderDetail": payArr
    }
    this.CommonService.postCall('IndentOrder/Create', payload).subscribe((res: any) => {
      if (res.status == false) {
        this.toastr.warning(res.message, "Indent Order");
        return;
      }
      this.close();
      this.toastr.success("Indent Order Created Successfully");
      this.loadData();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Indent Order Not created')
    })

  }



  add() {
    let val = this.myForm.getRawValue();
    this.myForm1.patchValue({
      DeptId: val.DeptId,
      storeId: val.storeId
    })

    this.isEdit = false;
    this.showtable = false;
    this.loadMaterials();
  }

  close() {
    this.showtable = true;
    this.isEdit = false;
    this.myForm.reset();
    this.myForm1.reset();
  }

  popClose() { }

  cancel() {
    this.router.navigate(['/home/dashboard']);
  }

  ok() {
    this.indentOrderList = [];
    this.indentOrderList = this.loadIndentOrder.filter(e => e.checked == true);
    console.log(this.indentOrderList);

    $('#myModal').modal('hide');
  }

  loadDataTable(table_data: any, table_name: string, className: string = ".dataTable") {
    if (table_data.length > 0) {
      $(className).DataTable().destroy();
      $(className).DataTable({ destroy: true, searching: false });
    }
    // setTimeout(() => { $(table_name).DataTable({ dom: 'Bfrtip', buttons: ['excel'] }); });
    setTimeout(() => { $(table_name).DataTable(); });
  }
}
