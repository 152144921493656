<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-body">
                                       <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="Save(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-xl-4 mb-3">
                                                        <label for="sel1">	Quotation Request No</label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" formControlName="">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                </div>
                                           </fieldset>
                                        </form> 
                                        <div class="footer mt-5 mb-4 text-center">
                                            <button type="button" class="btn btn-outline-success btn-sm" 
                                                (click)="Save(myForm)">Save</button>&nbsp;
                                            
                                          
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>