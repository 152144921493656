<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="content-wrapper p-0">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card rounded shadow-lg">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="mb-0">
                                    Indent Returns</h5>
                            </div>
                            <div class="card-body" [hidden]="!showtable">
                                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                    <div class="row">

                                        <div class="mb-2 mx-auto">
                                            <div class="form-group row">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Search For</label>
                                                <div class="col-md-3 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select formControlName='MATERIALGROUP_ID'
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="">Select</option>
                                                            <option *ngFor='let item of strLst'
                                                                [value]="item.materialgroup_id">
                                                                {{item.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2 mx-auto">
                                            <div class="form-group row">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    From Date </label>
                                                <div class="col-md-3 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input formControlName='from_date' placeholder='Start Date'
                                                            class="form-control form-control-sm shadow-sm rounded-lg "
                                                            type="date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2 mx-auto">
                                            <div class="form-group row">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    To Date </label>
                                                <div class="col-md-3 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input formControlName='to_date' placeholder='Start Date'
                                                            class="form-control form-control-sm shadow-sm rounded-lg "
                                                            type="date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2 mx-auto">
                                            <div class="form-group row">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Department<span class="text-danger">*</span></label>
                                                <div class="col-md-3 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select formControlName='MATERIALGROUP_ID'
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="">Select</option>
                                                            <option *ngFor='let item of strLst'
                                                                [value]="item.materialgroup_id">
                                                                {{item.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="myForm.get('MATERIALGROUP_ID').touched">
                                                        <span class="text-danger"
                                                            *ngIf="myForm.get('MATERIALGROUP_ID').hasError('required')">
                                                            This field is required.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary mb-1"
                                        (click)="cancel()">Close</button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-primary mb-1"
                                        (click)="add()">Add</button>
                                </div>
                                <div *ngIf="strLst.length">
                                    <table id="patreggraph" datatable
                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                        style="width:100%">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th> Indent Return No. </th>
                                                <th>
                                                    Indent Return Date
                                                </th>
                                                <th>Department</th>
                                                <th>Store</th>
                                                <th>No. of Materials</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of strLst">
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.status == true?"Active":"Inactive"}}</td>
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body" [hidden]="showtable">
                                    <form [formGroup]="myForm1">
                                        <div class="row">
                                            <div class="col-md-3 pt-4">
                                                <label for="">Department</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="department" formControlName="dept">
                                                        <option value="">select</option>
                                                        <option *ngFor="let item of strLst" [value]='item.unit_id'>
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="">Store Return</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="indentStore" formControlName="store">
                                                        <option value="">select</option>
                                                        <option *ngFor="let item of strLst" [value]='item.unit_id'>
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Indent Return No</label>
                                                <div class="input-group">
                                                    <input type="text" formControlName="indent"
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="date">Date</label>
                                                <div class="input-group">
                                                    <input type="date" formControlName="date"
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <button type="button" class="btn btn-sm btn-primary" id='close'
                                                    (click)="onPopup()">Add Materials</button>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Remarks</label>
                                                <div class="input-group">
                                                    <textarea formControlName='remarks'
                                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Acknowledged By</label>
                                                <div class="input-group">
                                                    <input type="text" formControlName="indent"
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Acknowledged Date</label>
                                                <div class="input-group">
                                                    <input type="text" formControlName="indent"
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="text-center pt-1 mt-2">
                                        <input type="submit" *ngIf="!isEdit" class="btn btn-sm btn-primary" value="Save"
                                            (click)="onSubmit(myForm)">&nbsp;&nbsp;
                                        <button type="button" class="btn btn-sm btn-outline-dark" id='close'
                                            (click)="close()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <!-- Modal Header -->
                                <div class="modal-header py-2">
                                    <h6 class=" modal-title w-100"> Material Search</h6>
                                    <button type="button" (click)="closeModal()" class="close" data-bs-dismiss="modal"><i
                                            class="fas fa-times"></i></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-3 pt-4">
                                            <label for="">Indent Issue No<span class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="department" formControlName="dept">
                                                    <option value="">select</option>
                                                    <option *ngFor="let item of strLst" [value]='item.unit_id'>
                                                        {{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="">Material Name<span class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="indentStore" formControlName="store">
                                                    <option value="">select</option>
                                                    <option *ngFor="let item of strLst" [value]='item.unit_id'>
                                                        {{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="">Batch No</label>
                                            <div class="input-group">
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="indentStore" formControlName="store">
                                                    <option value="">select</option>
                                                    <option *ngFor="let item of strLst" [value]='item.unit_id'>
                                                        {{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="date">Expiry Date</label>
                                            <div class="input-group">
                                                <input type="date" formControlName="date"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="indentNo">Unit</label>
                                            <div class="input-group">
                                                <input type="text" formControlName="indent"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="indentNo">Issued Qty</label>
                                            <div class="input-group">
                                                <input type="text" formControlName="indent"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="indentNo">Return Qty<span class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <input type="text" formControlName="indent"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="indentNo">Rate</label>
                                            <div class="input-group">
                                                <input type="text" formControlName="indent"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-4">
                                            <label for="indentNo">Value</label>
                                            <div class="input-group">
                                                <input type="text" formControlName="indent"
                                                    class="form-control form-select-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" (click)="addMaterail()"
                                        class="btn btn-sm  btn-save">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>