import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-copo-reports',
  templateUrl: './copo-reports.component.html',
  styleUrls: ['./copo-reports.component.css']
})
export class CopoReportsComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup
  cources: Array<any> = [];
  courceId: string = '';
  labelName: any = {};
  sheduleId: string = '';
  scheduls: Array<any> = [];
  examMarks: Array<any> = [];
  Marks: Array<any> = [];
  examName = [{ id: 1, name: 'CT' }, { id: 2, name: 'SE' }, { id: 3, name: 'PBE' }, { id: 4, name: 'FE' }]
  specificExam: Array<any> = [];
  specificExamNames: any;
  examNames: string = "";
  isTable: boolean = false;
  isTableLoad: boolean = false;
  isTableLoads: boolean = false;
  name: any = [];
  specific: string = "";
  headingName: any = {};
  thresholdcount: number;
  tot_CO_mapping_level: any;
  threshold_percent: Number;
  present_sum: number;
  absent_sum: number;
  isTables: boolean = false;
  percentage_performing_above_threshold: number;
  students_performance_level: number;
  tot_percentage_performing_above_threshold: number;
  tot_students_performance_level: number;
  tot_thresholdcount: number;
  tot_threshold_percent: number;
  newValue: any;
  isMain: boolean = false;
  questions: Array<any> = [];
  CO_mapping_level = "";
  seTable: Array<any> = [];
  ems_attainment_level: Array<any> = [];
  sheduleName: any;
  courseName: any;
  se_question_wise_threshold_per_count: number;
  length: number;
  workItemId: any;
  tenanates:Array<any>=[];
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');
  constructor( CommonService: CommonService,  toastr: ToastrService,private active : ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
 
    this.loadlabels();
    this.loadHeadings();
    this.tntLoad();
    this.getCourses(this.tenantCode);
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }
  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(id: any) {
    this.sheduleId = '';
    this.sheduleName = '';
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res
      // this.sheduleName = this.scheduls.find(e=>e.COURSESHD_ID == id).COURSESHD_NAME;
      // console.log(this.sheduleName);
      // this.scheduls = res.filter(e => res.find(e1 => e1.getName == e.id));

      // this.scheduls = this.scheduls.filter(e => res.find(e1 => e1.COURSESHD_NAME == e.id));
    }, e => { this.deactivateSpinner() })
  }


  examDropdown(id: any) {
    this.examMarks = [];
    this.sheduleId = id;
    let payLoad = {
      "courseshd_id": this.sheduleId,
      "tnt_code": this.tId||localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('CourseSchedule/GetExamNameByCourseShd', payLoad).subscribe((res: any) => {
      this.examMarks = this.examName.filter(e => res.find(e1 => e1.exam_names == e.id));


      // if (res.length > 3) {
      //   this.examMarks = res.filter(e => this.examName.find(e1 => e1.id == e.exam_names));        
      //   console.log(this.examMarks);
      // } else {
      //   this.examMarks = this.examName.filter(e => res.find(e1 => e1.exam_names == e.id));
      //   console.log(this.examMarks);
      // }
    })

  }

  specificDropdown(name: any) {
    this.specificExam = [];
    this.specificExamNames = name;
    let payLoad = {
      "exam_names": this.specificExamNames,
      "tnt_code": this.tId||localStorage.getItem('TenantCode'),
      "courseshd_id": this.sheduleId
    }
    this.CommonService.postCall('CourseSchedule/GetSpecificExamNameByExamName', payLoad).subscribe((res: any) => {
      this.specificExam = res;

    })
  }

  convetToPDF() {
    var data: any = document.getElementById('printtables');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('CopoReport.pdf'); // Generated PDF
      // window.close();
    });

  }

  tableLoad() {
    if (this.specificExamNames == '1') {
      this.isTable = true;
      this.isMain = true;
      this.isTables = false;
      this.isTableLoad = false;
      this.isTableLoads = false;
    } else if (this.specificExamNames == '2') {
      this.isTables = true;
      this.isMain = true;
      this.isTable = false;
      this.isTableLoad = false;
      this.isTableLoads = false;
    } else if (this.specificExamNames == '3') {
      this.isTableLoad = true;
      this.isMain = true;
      this.isTables = false;
      this.isTable = false;
      this.isTableLoads = false;
    }
    else if (this.specificExamNames == '4') {
      this.isTableLoads = true;
      this.isMain = true;
      this.isTables = false;
      this.isTable = false;
      this.isTableLoad = false;
    }
    else {
      this.isTable = false;
      this.isTables = false;
      this.isMain = false;
      this.isTableLoad = false;
      this.isTableLoads = false;
    }


    let payload = {
      "courseshd_id": this.sheduleId,
      "exam_name_id": this.examNames,
      "specific_exam_name": this.specific
      // "courseshd_id": 5275,
      // "exam_name_id": 1,
      // "specific_exam_name": "CT-10"
    }
    this.CommonService.postCall('CourseSchedule/Dynamic_colums_questions', payload).subscribe((res: any) => {
      this.table = res;
      this.ems_attainment_level = res[0]?.ems_attainment_level
      this.seTable = res[0]?.questions;
      this.threshold_percent = res[0]?.threshold_percent;
      this.present_sum = res[0]?.present_sum;
      this.absent_sum = res[0]?.absent_sum;
      this.CO_mapping_level = res[0]?.CO_mapping_level;
      this.percentage_performing_above_threshold = res[0]?.percentage_performing_above_threshold;
      this.students_performance_level = res[0]?.students_performance_level;
      this.se_question_wise_threshold_per_count = res[0]?.se_question_wise_threshold_per_count;
      this.thresholdcount = res[0]?.thresholdcount;
      this.tot_CO_mapping_level = res[0]?.tot_CO_mapping_level;
      this.tot_percentage_performing_above_threshold = res[0]?.tot_percentage_performing_above_threshold;
      this.tot_students_performance_level = res[0]?.tot_students_performance_level;
      this.tot_thresholdcount = res[0]?.tot_thresholdcount;
      this.tot_threshold_percent = res[0]?.tot_threshold_percent;
      this.length = res[0]?.questions.length;
      console.log(this.length);

    })
    this.sheduleName = this.scheduls.find(e => e.COURSESHD_ID == this.sheduleId).COURSESHD_NAME;
    this.courseName = this.cources.find(e => e.COURSE_ID == this.courceId).COURSE_NAME

  }


}
