<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add GRN Return</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <div class="row">
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> GRN Return Number
                                                        <span>:</span></label><span></span>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> Return Date <span>:</span></label><span></span>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Supplier Name<span
                                                            class="text-danger">*</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control form-select-sm">
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> </label>
                                                    <div class="input-group mb-3">
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" selected>select</option>
                                                            <option value="Purchase">Purchase</option>
                                                            <option value="Donation">Donation</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <label for="sel1">GRN Number<span
                                                        class="text-danger">*</span></label>
                                                    <div class="input-group mb-3">
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" selected>select</option>
                                                            <option value="Purchase">Purchase</option>
                                                            <option value="Donation">Donation</option>

                                                        </select>
                                                    </div>
                                                </div>


                                                <div class="table-responsive mt-5 mb-5">
                                                    <table id="patreggraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr style="white-space: nowrap;">
                                                                <th> Sl No </th>
                                                                <th> Material Name </th>
                                                                <th> Expiry Date </th>
                                                                <th> QOH </th>
                                                                <th> Unit Name</th>
                                                                <th>Recevied Quantity </th>
                                                                <th> Free Quantity </th>
                                                                <th> Returned Quantity </th>
                                                                <th> Purchase Prose </th>
                                                                <th> Net Amount </th>
                                                                <th> Tax(%) </th>
                                                                <th> Discount </th>
                                                                <th> Net Value </th>
                                                                <th> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <h4>Online Authorization</h4>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Authorized By</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" selected>select</option>

                                                    </select>

                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Authorized Date</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm">
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Remarks</label>
                                                    <div class="input-group">
                                                        <textarea placeholder="" class="form-control"
                                                            formControlName=""></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Toatl Amount</label>
                                                    <div class="input-group">
                                                        <input type="text" disabled
                                                            class="form-control form-select-sm">INR
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                        <div class="footer mt-5 mb-4 text-center">
                                            <button type="button" class="btn btn-outline-success btn-sm"
                                                (click)="onSubmit(myForm)">Save</button>&nbsp;

                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>