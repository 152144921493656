<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0" *ngIf="questions.length">
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="!isSubmited">
                                <div class="card">
                                    <div class="card-body">
                                        <!-- <div class="card-header">
                                    <countdown *ngIf="time" [config]="{leftTime: time}" (event)="handleEvent($event)">
                                    </countdown>
                                </div> -->

                                        <h5 class="card-title">
                                            <countdown *ngIf="time" [config]="{leftTime: time}"
                                                (event)="handleEvent($event)">
                                            </countdown>
                                        </h5>

                                        <div *ngFor="let item of questions">
                                            <div *ngIf="item.isShow">
                                                <!-- <div *ngIf="!numbool" class="q_header"><span>{{item.SRNO}} .</span>
                                                    {{item.QUESTION_QUESTION }}  </div> -->


                                                <div class="q_header"><span>{{srno}} .</span>
                                                    {{changeQuestion}} </div>

                                                <div *ngIf="item.TYPEID==1">

                                                    <textarea name="" [(ngModel)]="item.ANSWER_GIVENANSWER" id=""
                                                        cols="30" rows="5"></textarea>
                                                </div>
                                                <!-- *ngIf="item.TYPEID==0" -->
                                                <div *ngIf="item.TYPEID==0">
                                                    <!-- <div>
                                                        <div class="radio" *ngFor='let option of options'>
                                                            <label><input type="radio" [value]='option.QSTOPT_OPTION' id="id_{{item.QUESTION_ID}}"
                                                                 [checked]="option.IS_OPTION_RANDOM"   (change)="selectedQuestion('options',option.QSTOPT_OPTION)"
                                                                    [(ngModel)]="item.ANSWER_GIVENANSWER"
                                                                    name={{item.QUESTION_ID}}>
                                                                {{option.QSTOPT_OPTION}} {{option.IS_OPTION_RANDOM}}</label>
                                                        </div>
                                                    </div> -->
                                                    <div>
                                                        <div class="radio" *ngFor='let option of options'>
                                                            <label><input type="radio" [value]='option.QSTOPT_OPTION' id="id_{{item.QUESTION_ID}}"
                                                                 (change)="item.ANSWER_GIVENANSWER=option.IS_OPTION_RANDOM;selectedQuestion('options',option.QSTOPT_OPTION)"
                                                                    [(ngModel)]="option.IS_OPTION_RANDOM"
                                                                    name={{item.QUESTION_ID}}>
                                                                {{option.QSTOPT_OPTION}}</label>
                                                        </div>
                                                    </div>

                                                    <!-- <div >name={{item.QUESTION_ID}}
                                                        <div class="radio" *ngFor='let option of options;let i=index'>
                                                            <label><input type="radio" [value]='option.QSTOPT_OPTION'
                                                                  (change)="saveanswer($event,option.ANSWER_GIVENANSWER,i)" id="question_{{i}}"  [(ngModel)]="option.ANSWER_GIVENANSWER"
                                                                     name= "question_{{i}}" >
                                                                {{option.QSTOPT_OPTION}}</label>
                                                        </div>
                                                    </div> -->

                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-footer pt-2">
                                            <div class="d-flex justify-content-between">
                                                <!-- [disabled]="isPrev" -->
                                                <button type="button" [disabled]="isPrev"
                                                    (click)="selectedQuestion('prev')"
                                                    class="btn btn-secondary btn-sm">prev</button>
                                                <!-- <button class="btn btn-success btn-sm"
                                                    (click)="saveanswer()">save</button> -->
                                                <!-- <button class="btn btn-info btn-sm">Review</button> -->
                                                <!-- <button class="btn btn-danger btn-sm" (click)="reset()">Reset</button> -->

                                                <button type="button" [disabled]="isNext"
                                                    (click)="selectedQuestion('next')"
                                                    class="btn btn-secondary btn-sm">save&Next</button>
                                                <!-- <button>Finish</button> -->
                                            </div>

                                            <!-- <div class="row">
                                                <div class="btn-group col-md-4" role="group" aria-label="Basic example">
                                                    
                                                    
                                                </div>
                                                <div class="col-md-8">
                                                    <button style="float: right;" class="btn btn-sm btn-primary"
                                                        (click)="confirm()">submit</button>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isSubmited">
                                <div class="card w-100">
                                    <div class="card-body">
                                        <h5 class="card-title text-center">Post Assessment Result</h5>
                                        <div>
                                            <Label> <strong> Assessment Name &nbsp;: </strong> &nbsp;</Label>
                                            <span>{{AssessmentInformation.ASSESSMENT_ASSESSMENT_NAME}}</span><br>
                                            <label> <strong> Course Name &nbsp; : </strong> &nbsp;</label>
                                            <span>{{AssessmentInformation.COURSE_NAME}}</span>
                                            <p *ngIf="!result"> Your assessment is submitted for validation and will get
                                                back to
                                                you at the
                                                earliest</p>
                                            <div *ngIf="result">
                                                <label> <strong>Percentage Scored : </strong></label>
                                                <span>{{result.RESULT_PERCENTAGESCORED}}</span> <br>
                                                <label> <strong>Result : </strong></label>
                                                <span>{{result.RESULT_STATUS}}</span>
                                            </div>
                                        </div>
                                        <a *ngIf="result" class="check_answer" data-bs-toggle="modal"
                                            data-bs-target="#myModal" style="float: right;">Check Your Answers</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card" *ngIf="!isSubmited">
                                <div class="card-body">
                                    <h5 class="card-title">Questions</h5>
                                    <div class="btn-toolbar responsive" role="toolbar"
                                        aria-label="Toolbar with button groups">
                                        <div class="btn-group me-2" role="group" aria-label="First group"
                                            *ngFor="let item of mapElement;let i = index">
                                            <button type="button" id="qtnBtn_{{i}}"
                                                [ngClass]="!item.isAnswered && item.isReview ? 'reviewed': item.isAnswered ? 'answered':'notAnswered'"
                                                class="btn btn-outline-secondary optionsBtn"
                                                (click)="selectedQuestion(i)">{{item.SRNO}}</button>
                                            <!-- <input type="radio" value="">{{item.SRNO}} -->
                                        </div>
                                    </div>
                                    <div class="text-center pt-3">
                                        <button type="button" class="btn btn-danger btn-sm" (click)="confirm()">Finish
                                            Exam</button>
                                    </div>
                                    <!-- <br> -->
                                    <div class="pt-4">

                                        <ul style="list-style-type: none;">
                                            <li><span class="dot me-2" style=" background-color:#ff0000;"></span> Not
                                                attempted</li>
                                            <li><span class="dot me-2" style=" background-color:green;"></span>Answered
                                            </li>
                                            <!-- <li><span class="dot me-2" style=" background-color:orangered"></span>Not
                                                Answered</li> -->
                                            <li><span class="dot me-2"
                                                    style=" background-color:#0dcaf0"></span>Review</li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>


    <!-- Modal -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title w-100">Check Answers</h5>

                    <button type="button" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal-body">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Correct Answer</th>
                                <th>Given Answer</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of answers">
                                <td>{{item.QUESTION_QUESTION}}</td>
                                <td>{{item.QUESTION_ANSWER}}</td>
                                <td>{{item.ANSWER_GIVENANSWER}}</td>
                                <!-- <td>
                                <img *ngIf="item.ANSWER_ISINCORRECT" src="../../../assets/images/sprites/wrong.png"
                                    alt="">
                                <img *ngIf="item.ANSWER_ISCORRECT" src="../../../assets/images/sprites/right.png"
                                    alt="">

                            </td> -->
                                <td>
                                    <span>
                                        <i style="color: red;" class="fas fa-times" *ngIf="item.ANSWER_ISINCORRECT"></i>
                                        <i style="color: green;" class="fas fa-check" *ngIf="item.ANSWER_ISCORRECT"></i>
                                    </span>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>