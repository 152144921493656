import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { constants } from 'src/app/constants';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent implements OnInit, OnDestroy {
  myform: FormGroup;
  titles: Array<any> = [];
  genders: Array<any> = [];
  marriageStatus: Array<any> = [];
  branchs: Array<any> = [];
  maxDate: any = moment().format('yyyy-MM-DD');
  @Input() editData: any = {}
  @Input() parent: EditUserRegistrationComponent;
  // @Input() taskId :number;
  changed: boolean = false;
  personalData: any = {};
  years: any;
  roleId: number = 1;
  labelName: any = {};
  isTabsDisabled: boolean = (sessionStorage.isTabsDisabled || 'true') == 'true';
  courseYear: Array<any> = [];
  rolesId = localStorage.getItem('RoleId');
    constructor(private fb: FormBuilder, private CommonService: CommonService) {
  this.getAll();
  this.getLabels();
  this.getBranch();
}

ngOnDestroy(): void {
  sessionStorage.isTabsDisabled = true;
  
}

ngOnInit(): void {
  this.myform = this.fb.group({
    Title: ['', Validators.required],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    dob: ['', Validators.required],
    Gender: ['', Validators.required],
    MartialStatus: ['', Validators.required],
    idNumber: ['', Validators.required],
    enrollment_no: ['',Validators.required],
    Branch: ['', Validators.required],
    reference_number:[''],
    admission_number:[''],
    is_dropoff: [false],
    is_transport_required: [false],
    status: [''],
    ParentRelationShip: [''],
    ParentMobileNumber: [''],
    ParentName: [''],
    YearOfRegistration: [''],
    course_year_id: ['']
  })
    this.parent.childs['UserProfileData'] = this

}
ngOnChanges() {
  if (Object.keys(this.editData).length && !this.changed) {
    this.changed = true;
    this.personalData = this.editData['Personal'].length && this.editData['Personal'][0];
    this.dataTransform();
    this.getBranch();
    this.getLabels();
    this.loadCourseYear();
  }
}
activeSpinner() {
  this.CommonService.activateSpinner();
}

deactivateSpinner() {
  this.CommonService.deactivateSpinner()
}

getLabels() {
  this.labelName = {};
  this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
    this.labelName = res;
  })
}

loadCourseYear() {
  this.courseYear = [];
  this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear }).subscribe((res: any) => {
    this.courseYear = res;
  })


}
getAll() {
  this.activeSpinner();

  let title = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Title });//title
  let gender = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Gender });//getgender
  let marriageStatus = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.MaritalStatus });//GetMarriageStatus
  forkJoin([title, gender, marriageStatus]).subscribe((res) => {
    this.deactivateSpinner();
    [this.titles, this.genders, this.marriageStatus] = [...res]
    // this.titles=res[0];this.registrationTitle=res[1];this.genders=res[2] "YYYY-MM-DD HH:mm:ss
  }, err => {
    this.deactivateSpinner()
  })
}

getBranch() {
  let payLoad = {
    TENANT_CODE: this.personalData['TenantCode'],
    CREATEDBY: localStorage.getItem('UserId'),
    RoleId: localStorage.getItem('RoleId')
  }
  let apiUrl = constants['GetAdminCourses'] || 'GetAdminCourses'
  let uri = apiUrl + '/' + localStorage.getItem('UserId');
  let id = this.personalData['ROLEID'];
  let code = this.personalData['TenantCode'];
  this.CommonService.getCall(uri + '/' + 1 + '/' + code).subscribe(
    (res: any) => {
      this.branchs = res;
    }
  )
  //  this.CommonService.postCall('GetBranch',payLoad).subscribe(
  //  (res:any)=>{
  //this.branchs=res;
  // }
  // )
}

dataTransform() {
  let data = this.personalData; this.myform.markAllAsTouched;
  let controls = this.myform.controls;
  controls['dob'].setValue(moment(this.personalData.DOB).format('yyyy-MM-DD'))
  controls['FirstName'].setValue(data.FIRSTNAME);
  controls['LastName'].setValue(data.LASTNAME);
  controls['Gender'].setValue(data.GENDER);
  controls['Title'].setValue(data.TITLE);
  controls['MartialStatus'].setValue(data.MARTIALSTATUS);//STATUS
  controls['is_transport_required'].setValue(data.istranportrequired ? true : false);
  controls['status'].setValue(data.STATUS ? 1 : 0);
  controls['Branch'].setValue(data['CourseId']);
  controls['idNumber'].setValue(data['RollNumber']);
  controls['enrollment_no'].setValue(data['enrollment_no']);
  controls['YearOfRegistration'].setValue(data['YearOfRegistration']);
  controls['course_year_id'].setValue(data['course_yearid']);
  controls['ParentName'].setValue(data['ParentName']);
  controls['ParentMobileNumber'].setValue(data['Parent_Mobile_Number']);
  controls['ParentRelationShip'].setValue(data['ParentRelationShip']);
  controls['reference_number'].setValue(data['referencenumber']);
  controls['admission_number'].setValue(data['admissionnumber']);
  // controls['istranportrequired'].setValue(data['istranportrequired']);
  this.roleId = data['ROLEID'];
  if (this.roleId == 3) {
    this.getYear();
    controls['YearOfRegistration'].setValidators(Validators.required);
    controls['YearOfRegistration'].updateValueAndValidity();
    controls['is_dropoff'].setValue(data.isdropoff ? true : false);
    controls['ParentName'].setValidators(Validators.required);
    controls['ParentName'].updateValueAndValidity();
    controls['ParentMobileNumber'].setValidators(Validators.required);
    controls['ParentMobileNumber'].updateValueAndValidity();
    controls['ParentRelationShip'].setValidators(Validators.required);
    controls['ParentRelationShip'].updateValueAndValidity();
  }
  //  Object.keys(controls).map(key=>{
  //    controls[key].markAsTouched();
  //  })
}
getYear() {
  this.activeSpinner()
  let payload = {


  }
  this.CommonService.postCall('Loadyear', payload).subscribe((res: any) => {
    this.years = res;
    this.deactivateSpinner();
  }, e => this.deactivateSpinner());
}
}
