<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add 	
                                            Physical Stock Taking</h5>
                                        
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1 btn-sm"
                                                (click)="add()">Add</button>
                                        </div>
                                        <div class="row">
                                            <div>
                                                <p><b>Physical Stock Taking No :</b></p>
                                                <p><b>Store Name  :</b></p>
                                                <p><b>User Name  :</b></p>
                                                <p><b>Receiving Date  :</b></p>
                                            </div>
                                            <div class="col-xl-4 pt-4">
                                                <label for="sel1">Receiving Type</label>
                                                <div>
                                                    <input type="radio" formControlName="" value="1">Opening Stock 
                                                    <input type="radio" formControlName="" value="2">Regular Stock
                                                </div>
                                            </div>


                                            <div  class="table-responsive">
                                                <table id="patreggraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            <th> Sl No </th>
                                                            <th> Material Name </th>
                                                            <th> Batch No </th>
                                                            <th> Expiry Date </th>
                                                            <th> QOH </th>
                                                            <th> Physical Stock </th>
                                                            <th> Difference </th>
                                                            <th> Unit </th>
                                                            <th> Purchase Rate </th>
                                                            <th> Remarks </th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                          
                                            <div class="footer mt-5 mb-4 text-center">
                                                <button type="button" class="btn btn-outline-success btn-sm" 
                                                    (click)="Save()">Save</button>&nbsp;
                                                
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()" id="md_close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>