import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-request-for-quotation',
  templateUrl: './request-for-quotation.component.html',
  styleUrls: ['./request-for-quotation.component.css']
})
export class RequestForQuotationComponent extends BaseComponent implements OnInit {
  quotationList:Array<any>=[];

  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }

  ngOnInit(): void {
  }

  add(){
    this.route.navigate(['home/requestquotation/add']);
  }

}
