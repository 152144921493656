<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="Save(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-xl-4 mb-3">
                                                        <label for="sel1">Department</label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" formControlName="dept">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-xl-8 ">
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label" for="subDomain">With
                                                                Indent</label>
                                                            <input class="form-check-input" type="radio"
                                                                formControlName='isIndent'
                                                                (click)="isButtonchange($event.target)" name="isIndent"
                                                                id="sel1" value='1'>
                                                        </div>


                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label">Without Indent</label>
                                                            <input class="form-check-input" type="radio"
                                                                formControlName='isIndent' name="isIndent" id="sel1"
                                                                value='2' (click)="isButtonchange($event.target)">
                                                        </div>


                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label">Stock Transfer
                                                                Request</label>
                                                            <input class="form-check-input" type="radio"
                                                                formControlName='isIndent'
                                                                (click)="isButtonchange($event.target)" name="isIndent"
                                                                id="sel1" value='3'>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 mb-3">
                                                        <label for="sel1">Indent No </label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-sm"
                                                            id="sel1" formControlName="indNo">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-xl-4 mb-3" *ngIf="!isWithoutIndent">
                                                        <label for="sel1">Centers</label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" formControlName="centers">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-3" *ngIf="!isWithoutIndent">
                                                        <label for="sel1">Stock Transfer Pending Request </label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" formControlName="pending_Request">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div *ngIf="isWithoutIndent">

                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Material</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Purchase Unit</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Sale Unit</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">QOH</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">ROL</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">ROQ</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Required Qty.</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Rate</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Value</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    [attr.disabled]="isWithoutIndent"
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <label for="sel1">Required By </label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName=""
                                                                    class="form-control form-select-sm">
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <div class="footer mt-5 mb-4 text-center">
                                            <button type="button" class="btn btn-outline-success btn-sm"
                                                (click)="Save(myForm)">Save</button>
                                            &nbsp;

                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>