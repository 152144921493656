import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-schedule-master',
  templateUrl: './schedule-master.component.html',
  styleUrls: ['./schedule-master.component.css']
})

export class ScheduleMasterComponent implements OnInit {
  scheduleLst: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  userId = localStorage.getItem('UserId')
  tenantCode = localStorage.getItem('TenantCode')

  constructor(private active: ActivatedRoute, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.loadData();
    // this.loadlabels();
    // this.loadHeadings();
  }

  formInit() {
    this.myForm = this.fb.group({
      material_schedule_id: [0],
      name: [, Validators.required],
      status: [true],
      created_by: [],
      tnt_code: [],
      modified_by: []
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  // loadlabels() {
  //   this.labelName = {};
  //   this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.labelName = res;
  //     console.log(res);
  //   })
  // }

  // loadHeadings() {
  //   this.headingName = {};
  //   this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.headingName = res;
  //   })
  // }

  loadData() {
    this.activeSpinner();
    this.CommonService.getCall(`MaterialSchedule/GetAll/${this.tenantCode}`).subscribe((res: any) => {
      this.scheduleLst = [];
      setTimeout(() => {
        this.scheduleLst = res.data;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "material_schedule_id": 0,
      "name": value.name,
      "status": value.status,
      "created_by": +this.userId,
      "tnt_code": +this.tenantCode,
      "modified_by": 0
    }
    if (this.isEdit) {
      payload.material_schedule_id = this.editData.material_schedule_id;
      payload.modified_by = +this.userId;
      this.CommonService.postCall('MaterialSchedule/Update', payload).subscribe((res: any) => {
        this.toastr.success("MaterialSchedule Updated Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'MaterialSchedule Not Updated')
      })
    } else {
      this.CommonService.postCall('MaterialSchedule/Create', payload).subscribe((res: any) => {
        if (res.status == false) {
          this.toastr.warning(res.message, "MaterialSchedule");
          return;
        }
        this.toastr.success("MaterialSchedule Created Successfully");
        document.getElementById('md_close').click();
        this.loadData();
      }, err => {
        this.toastr.error(err.error ? err.error : 'SupplierType Not created')
      })
    }
  }

  edit(data: any) {
    this.isEdit = true;
    this.CommonService.getCall(`MaterialSchedule/GetById/${data?.material_schedule_id}`).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.datatransform()
      } else {
        this.editData = res.data;
        this.datatransform()
      }
    }, err => { }
    )
  }

  datatransform() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value = this.editData[key];
      if (value != undefined) control.setValue(value);
    });
  }

  add() {
    this.formInit();
    this.editData = {};
    this.isEdit = false;
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
  }
}
