<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 "> Price Master</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                      
                                        <div class="pt-2" *ngIf="getListData.length">
                                            <table datatable id="DataTables_Table_0" 
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Food Group </th>
                                                        <th> Food Item </th>
                                                        <th>General Price</th>
                                                        <th>Student Price</th>
                                                        <th>Staff Price</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of getListData">
                                                        <td>{{item.foodgroup_name}}</td>
                                                        <td>{{item.fooditem_name}}</td>
                                                        <td>{{item.foodprice_general}} Rs/-</td>
                                                        <td>{{item.foodprice_student}} Rs/-</td>
                                                        <td>{{item.foodprice_staff}} Rs/-</td>
                                                        <td>{{item.foodprice_status == true ? 'Active' : 'Inactive'}}</td>
                                                        <td style="display: flex;"> <span data-bs-toggle="modal"
                                                                data-bs-target="#myModal" src="" class="edit_icon"
                                                                (click)="edit(item.foodprice_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="false">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100">Add Price</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Price</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
              <!-- Modal body -->
              <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row ">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Select Food Group<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName="foodgroup_id" (change)="changeFoodGroup($event.target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let groupItem of groups" [value]="groupItem.foodgroup_id">
                                        {{groupItem.foodgroup_code}}</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Select Food Item<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName="fooditem_id" 
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let item of dropdownItemsList" [value]="item.fooditem_id">
                                        {{item.fooditem_name}}</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <!-- formControlName="CHAPTER_DESCRIPTION" -->
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                General Price<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="text" class="form-control" formControlName="foodprice_general">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Student Price<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="text" class="form-control" formControlName="foodprice_student">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Staff Price<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="text" class="form-control" formControlName="foodprice_staff">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Discount<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="text" class="form-control" formControlName="foodprice_discount"> 
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Start Date<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="date" class="form-control" formControlName="foofprice_effect_from">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                End Date<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="date" class="form-control" formControlName="foodprice_effect_to">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Status</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                    formControlName="foodprice_status">
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm?.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm?.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>