import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-departmentwise-requisitions',
  templateUrl: './departmentwise-requisitions.component.html',
  styleUrls: ['./departmentwise-requisitions.component.css']
})
export class DepartmentwiseRequisitionsComponent implements OnInit {
  myForm: FormGroup;
  strLst: Array<any> = [];
  TenantCode = localStorage.TenantCode;

  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void { 
    this.getStore();
      this.formInit();
  
    }
  
    formInit() {
      this.myForm = this.fb.group({
        fromDate: [((new Date()).toISOString().substring(0, 10))],
        toDate: [((new Date()).toISOString().substring(0, 10))],
      })
  
    }
    activeSpinner() {
      this.CommonService.activateSpinner();
    }
  
    deactivateSpinner() {
      this.CommonService.deactivateSpinner()
    }
  
    getStore() {
      this.activeSpinner();
      this.CommonService.getCall(`StoreMaster/GetAll/${this.TenantCode}`).subscribe((res: any) => {
        this.strLst = [];
        setTimeout(() => {
          this.strLst = res.data;
        }, 10)
        this.deactivateSpinner();
      }, e => { this.deactivateSpinner() })
    }
    
    onSubmit(form:FormGroup) { 
    
    }
  
   
  
    close(){
  
    }

}
