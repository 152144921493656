import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-material-master',
  templateUrl: './material-master.component.html',
  styleUrls: ['./material-master.component.css']
})
export class MaterialMasterComponent extends BaseComponent implements OnInit {
  masterList: Array<any> = [];
  rId: string = '';
  roles: Array<any> = [];
  tId: string = '';
  roleId: string = localStorage.getItem('RoleId');
  TenantCode = localStorage.TenantCode;
  tenanates: Array<any> = []
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  editData: any = {};
  matNameList: Array<any> = [];
  matGpNameList: Array<any> = [];
  isEdit:boolean=false;
  private readonly onDestroy = new Subscription();
  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }
  ngOnInit(): void {
    this.getmaterialName();
    this.getMaterialGpName();
    this.loadMaterial();
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getmaterialName() {
  
    this.matNameList = [];
    this.CommonService.getCall(`MaterialGroup/GetAllByActive/${this.TenantCode}/1`).subscribe((res) => {
      this.matNameList = res.data;
       });
  }

  getMaterialGpName() {
   
    this.matGpNameList = [];
    this.CommonService.getCall(`MaterialSubGroup/GetAllByActive/${this.TenantCode}/1`).subscribe((res) => {
      this.matGpNameList = res.data;
    });

  }
  enableOrDisabledSpinner(flag: boolean = true) {
    flag ? this.CommonService.activateSpinner() : this.CommonService.deactivateSpinner();
  }
 
  materialMasterChange() {
      this.loadMaterial();
  }

  loadMaterial() {
    this.loadDataTable('materialMaster');
    this.masterList=[];
   
    const ob1$ = this.CommonService.getCall(`MaterialMaster/GetAllByActive/${this.TenantCode}/1`).subscribe((res: any) => {
      this.masterList = res.data;
       setTimeout(() => { this.dtTrigger.next(); }, 100);
       console.log(this.masterList);
       this.enableOrDisabledSpinner(false);
     }, e => { this.enableOrDisabledSpinner(false); });
     this.onDestroy.add(ob1$);

  }


  add() {
    this.isEdit=false;
    this.route.navigate(['home/materailMaster/add'] ,{queryParams:{id:0}});
  }

  edit(material:any){
    this.isEdit =true;
    this.route.navigate(['home/materailMaster/edit'], { queryParams: { id: material.material_id} });
   
  }

  

}
