<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="card" [hidden]="!showTable">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-8 col-8 pt-2">
                                        <h6 class="mb-0">{{headingName.PlaceOrder}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="text-right mb-1">
                                    <button type="button" class="btn btn-primary" (click)="addData()">Add</button>
                                </div>
                                <div *ngIf="table.length">
                                    <table id="patergraph" datatable
                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                        style="width:100%">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Date</th>
                                                <th>Order Type</th>
                                                <th>Meal Type</th>
                                                <th>Total Price</th>
                                                <th>Token</th>
                                                <!-- <th>Action</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of table">
                                                <td>{{item.ordertran_date | date:'dd/MM/yyyy'}}</td>
                                                <td>{{item.ordertran_typeoforder}}</td>
                                                <td>{{item.ordertran_meal_type}}</td>
                                                <td>{{item.ordertran_totalamt}}</td>
                                                <td>{{item.ordertran_tokenid}}</td>
                                                <!-- <td style="display: flex;">
                                                    <span class="edit_icon" (click)="edit(item.ordertran_id)">
                                                        <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-center" *ngIf="!table.length">
                                    No Records to display
                                </div>
                            </div>
                        </div>

                        <div class="card" [hidden]="showTable">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-8 col-8 pt-2">
                                        <h6 *ngIf="!isEdit" class=" modal-title w-100">
                                            {{headingName.AddPlaceOrder}}
                                        </h6>
                                        <h6 *ngIf="isEdit" class=" modal-title w-100">
                                            {{headingName.EditPlaceOrder}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-8 offset-xl-2">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">

                                            <fieldset>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-1 ">
                                                        {{labelName?.Date}}<span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input placeholder="Driving Licence Number"
                                                                min="{{minDate}}" class="form-control form-control-sm"
                                                                formControlName="ordertran_date" required type="date">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-1 ">
                                                        {{labelName?.OrderType}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="ordertran_typeoforder"
                                                                (change)="changeType($any($event.target).value)">
                                                                <option value="" [selected]="true">select
                                                                </option>
                                                                <option [value]=1>Regular Meal</option>
                                                                <option *ngFor="let item of orderTypeList"
                                                                    [value]='item.ordertype_id'>
                                                                    {{item.ordertype_name}}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="enableType">
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label mt-1 ">
                                                            {{labelName?.MealType}}<span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong>
                                                                &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="ordertran_meal_type">
                                                                    <option value="" [selected]="true">
                                                                        select
                                                                    </option>
                                                                    <option *ngFor="let item of mealType"
                                                                        [value]='item.id'>
                                                                        {{item.name}}</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="enableItem">
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label mt-1 ">
                                                            {{labelName?.PriceCategory}}Price Category <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong>
                                                                &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="price_cat"
                                                                    (change)="changeFoodGroup($any($event.target).value)">
                                                                    <option value="" [selected]="true">
                                                                        select
                                                                    </option>
                                                                    <option value=1>Student Price</option>
                                                                    <option value=2>Staff Price</option>
                                                                    <option value=3>General Price</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="enableItem">
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label mt-1 ">
                                                            {{labelName?.FoodGroup}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong>
                                                                &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="foodgroup_id"
                                                                    [(ngModel)]="foodGroup"
                                                                    (change)="changeFoodGroup($any($event.target).value)">
                                                                    <option value="" [selected]="true">
                                                                        select
                                                                    </option>
                                                                    <option *ngFor="let item of groups"
                                                                        [value]='item.foodgroup_id'>
                                                                        {{item.foodgroup_name}}</option>

                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>


                                                <div *ngIf="enableItem">
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label mt-1 ">
                                                            {{labelName?.FoodItem}}<span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong>
                                                                &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="fooditem_id"
                                                                    (change)="changeFoodItem($any($event.target).value)">
                                                                    <option value="" [selected]="true">
                                                                        select
                                                                    </option>
                                                                    <option *ngFor="let item of dropdownItemsList"
                                                                        [value]='item.fooditem_id'>
                                                                        {{item.fooditem_name}}</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="enableItem"> <button type="button" (click)="add(myForm)"
                                                        class="btn btn-sm  btn-save text-right">Add</button>
                                                </div>
                                                <div *ngIf="enableItem" class="form-group row pt-4">
                                                    <table id="patreggraph"
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th>Food Group </th>
                                                                <th> Food Item </th>
                                                                <th>Unit Price</th>
                                                                <th> Quantity </th>
                                                                <th> Total Price</th>
                                                                <th> Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of lists; let i=index">
                                                                <td>{{item.foodgroup_name}}</td>
                                                                <td>{{item.fooditem_name}}</td>
                                                                <td>{{item.unitprice}}</td>
                                                                <td><button type="button" (click)="pluse('plus',i)"
                                                                        class="btn btn-sm btn-primary text-right">+
                                                                    </button> &nbsp; {{item.ordertran_qty}}
                                                                    &nbsp;
                                                                    <button type="button" (click)="pluse('minus',i)"
                                                                        class="btn btn-sm btn-primary text-right">-</button>
                                                                </td>
                                                                <td>{{item.ordertran_price}}</td>
                                                                <td> <button type="button" (click)="delete(i)"
                                                                        class="btn btn-sm  btn-danger">Delete</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>


                                                <div *ngIf="enableType || enableItem">
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.PaymentMode}}<span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">

                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        formControlName="ordertran_paymentmode"
                                                                        name="ordertran_paymentmode" id="inlineRadio1"
                                                                        [value]="1" checked>
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio1">Cash</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        formControlName="ordertran_paymentmode"
                                                                        name="ordertran_paymentmode" id="inlineRadio2"
                                                                        [value]="2">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio2">Card</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        formControlName="ordertran_paymentmode"
                                                                        name="ordertran_paymentmode" id="inlineRadio3"
                                                                        [value]="3">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio3">Wallets</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <div class="text-center pt-4">
                                            <button type="button" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save">Generate
                                                Token</button> &nbsp;
                                            <button type="button" class="btn btn-sm btn-danger"
                                                class="btn btn-danger  btn-sm" id='md_close'
                                                (click)="close()">close</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModal" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl ">
        <div class="modal-content">

            <div class="modal-header">
                <h6 class=" modal-title w-100">Token Generate </h6>
                <button type="button" (click)="cancel()" class="btn-close btn-close-dark" aria-label="Close"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="card-body">
                <div id="print">
                    <table class="table table-striped table-bordered" style="width:100%">
                        <thead class="top">
                            <tr>
                                <th>Food Items</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <!-- <th>Total Price</th> -->

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of viewData">
                                <td>{{item.fooditem_name}}</td>
                                <td>{{item.ordertran_qty}}</td>
                                <td>{{item.ordertran_price}}</td>
                                <!-- <td>{{item.ordertran_totalamtrint}}</td> -->

                            </tr>
                            <tr>
                                <td></td>
                                <td>Total Amount</td>
                                <td>{{totalAmont}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" (click)="Print()">
                    Print</button>

                <button type="button" class="btn btn-sm btn-danger" id='close_id' (click)="cancel()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>