import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { BaseComponent } from '../base.component'; import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-student-attendance',
  templateUrl: './add-student-attendance.component.html',
  styleUrls: ['./add-student-attendance.component.css']
})
export class AddStudentAttendanceComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: any = '';
  isPeriod: Boolean = false;
  isTable: Boolean = false;
  subjects: Array<any> = [];
  isCheck: boolean = false;
  attendanceList: Array<any> = [];
  payload: {};
  check: any;
  checked: boolean;
  selectAll: boolean;
  today: any = new Date();
  todayDate: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadCampusDropdown();
    this.getCourses();
    this.calDate();
    this.loadlabels();
    this.loadHeadings();
  }
  ngOnInit(): void {
    this.myForm = this.fb.group({
      attendance_type: [''],
      date: ['', Validators.required],
      campus_id: [''],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      period_id: [''],
      subject_id: [''],
      name: [''],
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }



  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })

  }

  calDate() {
    var d = this.today,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.todayDate = [year, month, day].join('-');
    console.log(this.todayDate);
    return [year, month, day].join('-');
  }



  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  getSubjectsbyCourse(id: any) {
    let payLoad = {
      COURSE_ID: id,
      TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.activeSpinner();
    this.CommonService.postCall('GetSubjects', payLoad).subscribe(
      (res: any) => {
        this.subjects = res;
        this.deactivateSpinner();
      }, err => {
        this.deactivateSpinner();
      }
    )
  }

  priodeChange(event: any) {
    let val = event.target.value;
    if (val == "priodewise") {
      this.isPeriod = true;
    } else {
      this.isPeriod = false;
    }
  }

  checkAttendance(item: any, event: any, i: any) {
    this.isCheck = true;
    if (event.target.checked) {
      this.table[i]['attendance_status'] = "Present"
    } else {
      this.table[i]['attendance_status'] = "Absent"
    }
  }

  checkAll(event: any) {
    this.isCheck = true;
    this.table.forEach(e => {
      e.checked = event.target.checked

      if (event.target.checked == true) {
        this.table.forEach(a => a.checked = true);
        e.attendance_status = "Present"
      } else {
        e.attendance_status = "Absent"
      }
    });


    if (event.target.checked == true) {
      this.table.forEach(a => a.checked = true);
    }
    else {
      this.table.forEach(a => a.checked = false);
    }
  }

  onSubmit(form: any) {
    this.table = [];
    let value = form.value;
    this.isTable = false;
    this.payload = {
      "attendance_type": value.attendance_type,
      "date": value.date,
      "courese_id": value.class_id,
      "course_schedule_id": value.section_id,
      "period_id": value.period_id | 0,
      "subject_id": value.subject_id | 0,
    }
    this.CommonService.postCall('TimeTable/GetStudentList', this.payload).subscribe((res: any) => {
      setTimeout(() => {
        this.table = res;
        this.table.map(a => a.checked);
        this.selectAll = this.table.find(e => e.checkstatus)
        this.renderDataTable();
      }, 10)
    })
  }

  submitAttendance(form: any) {
    let attendances: any = [];
    this.table.forEach(element => {
      let obj = {
        "student_id": element.student_id,
      }
      if (element["attendance_status"] == undefined) {
        obj['attendance_status'] = "Absent"
        element['attendance_status'] = "Absent"
      } else {
        obj['attendance_status'] = element.attendance_status
        element['attendance_status'] = element.attendance_status
      }
      attendances.push(obj);

    });
    this.payload['attendances'] = attendances;
    this.payload['tnt_code'] = localStorage.getItem('TenantCode')
    this.payload['modified_by'] = localStorage.getItem('UserId')
    this.payload['created_by'] = localStorage.getItem('UserId')
    this.CommonService.postCall('TimeTable/CreateStudentAttendanceList', this.payload).subscribe((res: any) => {
      this.attendanceList = res;
      this.route.navigate(['home/studentAttendance'])
      this.toastr.success("Attendance Updated Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Attendance Not Updated')
    })
  }

  changeAttendance(pId: any, status: any) {
    let pay = this.myForm.value
    let payLoad = {
      "attendance_type": pay.attendance_type,
      "date": pay.date,
      "courese_id": pay.class_id,
      "course_schedule_id": pay.section_id,
      "period_id": pay.period_id | 0,
      "subject_id": pay.subject_id | 0,
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "attendances": [
        {
          "student_id": pId,
          "attendance_status": status
        },

      ],
    }
    if (confirm(`Are you sure you want to ${status} this Student ?`)) {
      this.CommonService.postCall('TimeTable/CreateStudentAttendanceList', payLoad).subscribe((res: any) => {
        this.onSubmit(this.myForm);
        this.isTable = true;
        this.toastr.success("Attendance Posted Successfully");
      })
    }
  }

  close() {
    this.route.navigate([`home/studentAttendance/${this.workItemId}`]);
  }

}
