import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-list-of-grn',
  templateUrl: './list-of-grn.component.html',
  styleUrls: ['./list-of-grn.component.css']
})
export class ListOfGRNComponent implements OnInit {
  myForm: FormGroup;
  supplierList: Array<any> =[];
  material_id :any;

  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getSupplier();
    this.formInit();

  }

  formInit() {
    this.myForm = this.fb.group({
      fromDate: [((new Date()).toISOString().substring(0, 10))],
      toDate: [((new Date()).toISOString().substring(0, 10))],
    })
   
  }
  getSupplier() {
    let id=this.material_id 
    this.supplierList = [];
    this.CommonService.getCall(`MaterialMaster/GetMaterialSupplierByID/${id}`).subscribe((res) => {
      this.supplierList = res.data;
    });


  }


  onSubmit(form:FormGroup) { 
    
  }

 

  close(){

  }


}
