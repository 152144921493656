<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.COPOReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset> -->
                                        <div class="row">
                                            
                                            <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="getCourses($any($event.target).value)"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>{{labelName.Course}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select [(ngModel)]='courceId '
                                                        (change)="courceChange($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select
                                                        </option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-3">
                                                <label>{{labelName.CourseSchedule}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select (change)="examDropdown($any($event.target).value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select</option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-3">
                                                <label>{{labelName.ExamName}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select [(ngModel)]='examNames'
                                                        (change)="specificDropdown($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select</option>
                                                        <option *ngFor="let name of examMarks" [value]='name.id'>
                                                            {{name.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-3">
                                                <label>{{labelName.SpecificExamName}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select [(ngModel)]='specific' (change)="tableLoad()"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select </option>
                                                        <option *ngFor="let item of specificExam"
                                                            [value]='item.specific_exam_name'>
                                                            {{item.specific_exam_name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>

                                                <div *ngIf="isMain" id="printtables">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped table-bordered mt-3">
                                                            <h3 class="mb-0 text-center" style="width:500%"
                                                                *ngIf="isTable"> CT Report </h3>
                                                            <h3 class="mb-0 text-center" style="width:500%"
                                                                *ngIf="isTables"> SE Report
                                                            </h3>
                                                            <h3 class="mb-0 text-center" style="width:500%"
                                                                *ngIf="isTableLoad"> PBE Report
                                                            </h3>
                                                            <h3 class="mb-0 text-center" style="width:500%"
                                                                *ngIf="isTableLoads"> FE Report
                                                            </h3>
                                                            <tr>
                                                                <td colspan="4" class="border-0">
                                                                    <table class="border-0 w-100">
                                                                        <tr>
                                                                            <td class="border-0">
                                                                                <div
                                                                                    style="border-bottom:3px solid #d8eff8;">
                                                                                    <div class="row pt-4">
                                                                                        <div class="col-xl-4 col-md-4">
                                                                                            <p><strong>Course :
                                                                                                </strong>
                                                                                                {{this.courseName}}</p>
                                                                                        </div>
                                                                                        <div class="col-xl-8 col-md-8">
                                                                                            <p><strong>Subject :
                                                                                                </strong>
                                                                                                {{this.sheduleName}}
                                                                                            </p>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style="width:20%">S.No</th>
                                                                <th>User Id</th>
                                                                <th>Student Name</th>
                                                                <th> Questions </th>
                                                            </tr>
                                                            <tr colspan="3" *ngFor="let item of table;let i = index">
                                                                <td>{{i+1}}</td>
                                                                <td>{{item.userid}}</td>
                                                                <td>{{item.studentname}}</td>
                                                                <td>
                                                                    <table class="table  table-bordered mt-2">

                                                                        <tr class="text-center">
                                                                            <th *ngFor="let que of item.questions">
                                                                                Q {{que.questions}}</th>
                                                                            <th>Total</th>
                                                                        </tr>


                                                                        <tr>
                                                                            <td class="text-center"
                                                                                *ngFor="let que of item.questions"
                                                                                class="text-center">{{que.marks}}</td>
                                                                            <td class="text-center">
                                                                                {{item.studentattendance
                                                                                == 'Absent' ? 'AB' : item.total_sum}}
                                                                            </td>
                                                                        </tr>

                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="isTable" class="pt-4">
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th style="width: 50%;">Threshold Marks</th>
                                                                    <td> </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Performance threshold </td>
                                                                    <td>{{threshold_percent}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. Of Students Present
                                                                    </td>
                                                                    <td> {{present_sum}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. Of Students Absent </td>
                                                                    <td>{{absent_sum}} </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Number of students
                                                                        performing above
                                                                        threshold </td>
                                                                    <td>{{thresholdcount}} </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">% of students performing
                                                                        above
                                                                        threshold </td>
                                                                    <td> {{percentage_performing_above_threshold}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Students performance level
                                                                    </td>
                                                                    <td>{{students_performance_level}} </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">CO mapping level</td>
                                                                    <td>{{CO_mapping_level}} </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div *ngIf="isTables" class="pt-4">
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th style="width: 40%;"> Marks</th>
                                                                    <th  
                                                                        class="text-end"> </th>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Performance threshold </td>
                                                                    <td  class="text-end">
                                                                        {{threshold_percent}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Present
                                                                    </td>
                                                                    <td  class="text-end">
                                                                        {{present_sum}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Absent </td>
                                                                    <td  class="text-end">
                                                                        {{absent_sum}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>


                                                                <tr>
                                                                    <td style="width: 50%;">Number of students
                                                                        performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.se_question_wise_threshold_per_count}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_thresholdcount}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">% of students performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.percentage_performing_above_threshold}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_threshold_percent}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Students performance level
                                                                    </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.students_performance_level}} </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_students_performance_level}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">CO mapping level</td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.co_mapping_level}} </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="isTableLoad" class="pt-4">
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th style="width: 40%;"> Marks</th>
                                                                    <th  
                                                                        class="text-end"> </th>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Performance threshold </td>
                                                                    <td  class="text-end">
                                                                        {{threshold_percent}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Present
                                                                    </td>
                                                                    <td  class="text-end">
                                                                        {{present_sum}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Absent </td>
                                                                    <td  class="text-end">
                                                                        {{absent_sum}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>


                                                                <tr>
                                                                    <td style="width: 50%;">Number of students
                                                                        performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.se_question_wise_threshold_per_count}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_thresholdcount}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">% of students performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.percentage_performing_above_threshold}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_threshold_percent}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Students performance level
                                                                    </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.students_performance_level}} </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_students_performance_level}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">CO mapping level</td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.co_mapping_level}} </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="isTableLoads" class="pt-4">
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th style="width: 40%;"> Marks</th>
                                                                    <th  
                                                                        class="text-end"> </th>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Performance threshold </td>
                                                                    <td  class="text-end">
                                                                        {{threshold_percent}}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Present
                                                                    </td>
                                                                    <td  class="text-end">
                                                                        {{present_sum}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">No. OF Students Absent </td>
                                                                    <td  class="text-end">
                                                                        {{absent_sum}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table id="patergraph"
                                                            class=" table table-bordered mt-2 pt-2 table-sm small"
                                                            style="width:100%">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                            </thead>
                                                            <tbody>


                                                                <tr>
                                                                    <td style="width: 50%;">Number of students
                                                                        performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.se_question_wise_threshold_per_count}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_thresholdcount}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">% of students performing
                                                                        above
                                                                        threshold </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.percentage_performing_above_threshold}}
                                                                    </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_threshold_percent}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">Students performance level
                                                                    </td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.students_performance_level}} </td>
                                                                    <td style="font-weight:bold;">
                                                                        {{tot_students_performance_level}}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50%;">CO mapping level</td>
                                                                    <td *ngFor="let item of seTable">
                                                                        {{item.co_mapping_level}} </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="isTables || isTableLoad || isTableLoads">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Course Outcome</th>
                                                                    <th>No. Of Questions Count</th>
                                                                    <th>Average Level</th>
                                                                    <th>Attainment Level</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of ems_attainment_level">
                                                                    <th>{{item.course_outcomes}}</th>
                                                                    <td>{{item.no_ofquestions_count}}</td>
                                                                    <td>{{item.average_level == "NaN" ? "NaN" : item.average_level | number : '1.2-2'}} </td>
                                                                    <td>{{item.attainment_level == "NaN" ? "NaN" : item.attainment_level | number : '1.2-2'}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>





                                                <div class="footer text-center pb-2" *ngIf="isMain">
                                                    <button class="btn btn-save btn-sm"
                                                        (click)="convetToPDF()">download</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>