<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-0 ps-0">
        <div class="default_class">
            <div class="content-wrapper p-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card rounded shadow-lg">
                                <div class="card-header  headerbg" style="border-bottom: 3px solid #d8eff8;">
                                    <h5  class="mb-0" *ngIf="id == 0">Add Material Master</h5>
                                    <h5  class="mb-0" *ngIf="id > 0">Edit Material Master</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-generic-tab"
                                                    data-bs-toggle="tab" data-bs-target="#nav-generic" type="button"
                                                    role="tab" aria-controls="nav-generic" aria-selected="true">Generic
                                                    Details</button>&nbsp;
                                                <button class="nav-link" id="nav-supplier-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-supplier" type="button" role="tab"
                                                    aria-controls="nav-supplier" aria-selected="false">Preferred
                                                    Supplier</button>&nbsp;
                                                <button class="nav-link" id="nav-history-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-history" type="button" role="tab"
                                                    aria-controls="nav-history" aria-selected="false">Purchase
                                                    History</button>&nbsp;
                                                <button class="nav-link" id="nav-holding-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-holding" type="button" role="tab"
                                                    aria-controls="nav-holding" aria-selected="false">Holding Store
                                                    Details</button>&nbsp;
                                                <button class="nav-link" id="nav-transaction-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-transaction" type="button" role="tab"
                                                    aria-controls="nav-transaction" aria-selected="false">Transaction
                                                    Details</button>
                                            </div>
                                        </nav>

                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-generic" role="tabpanel"
                                                aria-labelledby="nav-generic-tab">
                                                <div class="row">
                                                    <div>
                                                        <app-generic-details [id]="id"></app-generic-details>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="nav-supplier" role="tabpanel"
                                                aria-labelledby="nav-supplier-tab">

                                                <div>
                                                    <app-preferred-supplier [id]="id"></app-preferred-supplier>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="nav-history" role="tabpanel"
                                                aria-labelledby="nav-history-tab">
                                                <div class="row">
                                                    <div>
                                                        <app-purchase-history></app-purchase-history>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="tab-pane" id="nav-holding" role="tabpanel"
                                                aria-labelledby="nav-holding-tab">
                                                <div class="row">
                                                    <div>
                                                        <app-holding-story-details></app-holding-story-details>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="nav-transaction" role="tabpanel"
                                                aria-labelledby="nav-transaction-tab">
                                                <div class="row">
                                                    <div>
                                                        <app-transaction-details></app-transaction-details>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>