import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-food-item',
  templateUrl: './food-item.component.html',
  styleUrls: ['./food-item.component.css']
})
export class FoodItemComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = null;
  isDisable: boolean = false;
  table: Array<any> = [];
  foodGroupList: Array<any> = [];
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  date: any = new Date();
  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.load();
    this.loadFoodGroup();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      foodgroup_id: ['', [Validators.required,]],
      fooditem_name: ['', [Validators.required,]],
      fooditem_code: ['', Validators.required],
      fooditem_weight: [''],
      fooditem_status: ['true', Validators.required],
      fooditem_description: ['', Validators.required],
    });

  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }



  load() {
    this.activiceSpinner();
    this.CommonService.postCall(`FoodItem/GetList`, { tnt_code: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  loadFoodGroup() {
    this.foodGroupList = [];
    this.CommonService.postCall(`FoodGroup/GetList`, { tnt_code: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.foodGroupList = res;
    })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "fooditem_code": value.fooditem_code,
      "fooditem_name": value.fooditem_name,
      "fooditem_description": value.fooditem_description,
      "foodgroup_id": value.foodgroup_id,
      "fooditem_weight": value.fooditem_weight == "" ? 0 : value.fooditem_weight,
      "fooditem_status": value.fooditem_status,
      "tnt_code": this.TenantCode,

    }
    if (this.isEdit) {
      payload['fooditem_modifieddate'] = this.date;
      payload['fooditem_modifiedby'] = this.userId;
      payload['fooditem_id'] = this.editData.fooditem_id;
      this.CommonService.postCall('FoodItem/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Food Items Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Food Items Not Updated')
      })
    } else {
      payload['fooditem_createddate'] = new Date();
      payload['fooditem_createdby'] = this.userId;
      this.CommonService.postCall('FoodItem/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Food Items Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error.message ? err.error.message : 'Food Items Not Created')

      })
    }


  }
  edit(foodItemId: any) {
    this.isEdit = true;
    this.myForm.reset();
    this.CommonService.postCall('FoodItem/Get', { "fooditem_id": foodItemId }).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }


  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
  }

  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }
}