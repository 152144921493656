<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="content-wrapper p-0">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card rounded shadow-lg">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="mb-0">Indent Issue</h5>
                            </div>
                            <div class="card-body" [hidden]="!showtable">
                                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                    <div class="row">

                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Store</label>
                                            <div class="input-group col-md-8">
                                                <select formControlName='storeId'
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="">Select</option>
                                                    <option *ngFor='let item of strLst' [value]="item.store_id">
                                                        {{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Department<span class="text-danger">*</span></label>

                                            <div class="input-group col-md-8">
                                                <select formControlName='DeptId'
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="">Select</option>
                                                    <option *ngFor='let item of departmentList'
                                                        [value]="item.DEPARTMENT_ID">
                                                        {{item.DEPARTMENT_NAME}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('DeptId').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('DeptId').hasError('required')">
                                                    This field is required.
                                                </span>
                                            </div>

                                        </div>



                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                From Date </label>

                                            <div class="input-group col-md-8">
                                                <input formControlName='fromDate' placeholder='Start Date'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    type="date">
                                            </div>

                                        </div>


                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                To Date </label>

                                            <div class="input-group col-md-8">
                                                <input formControlName='toDate' placeholder='Start Date'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    type="date">
                                            </div>

                                        </div>


                                        <div class="form-group col-md-8 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Indent Issue Status </label>
                                            <div class="col-md-8 inputGroupContainer">
                                                <input type="radio" name="isssueStatus" id="radio1" value=1 checked
                                                    formControlName="isssueStatus">
                                                &nbsp;<label class="form-check-label" for="internal">With Indent</label>
                                                &nbsp;&nbsp;
                                                <input type="radio" name="isssueStatus" id="radio2" value=2
                                                    formControlName="isssueStatus">&nbsp;<label class="form-check-label"
                                                    for="external">Without Indent</label>
                                                &nbsp;&nbsp;
                                                <input type="radio" name="isssueStatus" id="radio3" value=0
                                                    formControlName="isssueStatus">&nbsp;<label class="form-check-label"
                                                    for="external">All</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type="button" [disabled]="myForm.invalid"
                                                    (click)="onSubmit(myForm)"
                                                    class="btn btn-sm btn-primary mb-1">Search</button>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <div class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary mb-1"
                                        (click)="cancel()">Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-primary mb-1" (click)="add()"
                                        [disabled]="myForm.invalid">Add</button>
                                </div>
                                <div *ngIf="indetOrderGetlist.length">
                                    <table id="patreggraph" datatable
                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                        style="width:100%">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Indent No </th>
                                                <th>Date</th>
                                                <th>Issue No</th>
                                                <th>Department</th>
                                                <th>Indent Issue Status</th>
                                                <th>Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of indetOrderGetlist">
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.status == true?"Active":"Inactive"}}</td>
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.status == true?"Active":"Inactive"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body" [hidden]="showtable">
                                    <form [formGroup]="myForm1">
                                        <div class="row">
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Indent Issue Number</label>
                                                <div class="input-group">
                                                    <input type="text" formControlName="indent" disabled
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="date">Indent Issue Date</label>
                                                <div class="input-group">
                                                    <input type="date" formControlName="date" disabled
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="">Department</label>
                                                <div class="input-group">
                                                    <select formControlName='DeptId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="">Select</option>
                                                        <option *ngFor='let item of departmentList'
                                                            [value]="item.DEPARTMENT_ID">
                                                            {{item.DEPARTMENT_NAME}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for=""></label>
                                                <div class="input-group">
                                                    <input type="radio" name="status" id="radio1" [value]=1
                                                        formControlName="status" (click)="onPopup()"
                                                        (change)="changeIndent(1)">
                                                    &nbsp;<label class="form-check-label" for="internal">With
                                                        Indent</label>
                                                    &nbsp;&nbsp;
                                                    <input type="radio" name="status" id="radio2" [value]=2
                                                        formControlName="status" (click)="tableIMandMB()"
                                                        (change)="changeIndent(1)">&nbsp;<label class="form-check-label"
                                                        for="external">Without Indent</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="card-body mt-5" [hidden]="!showtableIndent">
                                        <table id="table4"
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                <tr>
                                                    <th></th>
                                                    <th>Material Name</th>
                                                    <th>Batch No</th>
                                                    <th>Unit</th>
                                                    <th>Expiry Date</th>
                                                    <th>QOH</th>
                                                    <th>Issue Qty</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of checkedPendingList">
                                                    <td><input type="checkbox" [(ngModel)]="item.checked"
                                                            [ngModelOptions]="{standalone: true}"></td>
                                                    <td>{{item.code}}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.code}}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.code}}</td>
                                                    <td><input type="text" [(ngModel)]="item.name"
                                                            [disabled]="!item.checked"
                                                            [ngModelOptions]="{standalone: true}"></td>
                                                    <td>{{item.status ==
                                                        true?"Active":"Inactive"}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-center mt-2">
                                            <button type="button" class="btn btn-sm btn-primary">
                                                Save
                                            </button>&nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-save">
                                                Print
                                            </button>&nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-outline-dark" id='close'
                                                (click)="close()">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="card-body" [hidden]="!showIMandMB">
                                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                            <h5 class="mb-0">Indent Materials</h5>
                                            <h5 class="" style="float: right
                                            ;">Material Batches</h5>
                                            <button type="button"
                                                class="text-right col-md-5 btn btn-sm btn-primary mb-1"
                                                (click)="addIndentMaterials()">Add</button>
                                            <button type="button"
                                                class="text-right col-md-5 btn btn-sm btn-primary mb-1"></button>
                                        </div>
                                        <div class=" modal" id="myModal1" data-bs-backdrop="static"
                                            data-bs-keyboard="false">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <!-- Modal Header -->
                                                    <div class="modal-header py-2">
                                                        <h6 class=" modal-title w-100"> Add Materails </h6>
                                                        <button type="button" (click)="closeModal()" class="close"
                                                            data-bs-dismiss="modal"><i
                                                                class="fas fa-times"></i></button>
                                                    </div>
                                                    <div class="modal-header py-2">
                                                        <h6 class=" modal-title w-100" style="float: left"> Material
                                                            Batches </h6>
                                                    </div>
                                                    <!-- Modal body -->
                                                    <div class="modal-body">
                                                        <div *ngIf="strLst.length">
                                                            <table id="table3"
                                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                                style="width:100%">
                                                                <thead class="top"
                                                                    style="background-color: #624FD7; color:#ffffff;">
                                                                    <tr>
                                                                        <th>Material Name</th>
                                                                        <th>Generic Name</th>
                                                                        <th>Issued Qty</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of strLst">
                                                                        <td>{{item.code}}</td>
                                                                        <td>{{item.name}}</td>
                                                                        <td>{{item.status ==
                                                                            true?"Active":"Inactive"}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="text-center pt-1 mt-2">
                                        <input type="submit" *ngIf="!isEdit" class="btn btn-sm btn-primary" value="Save"
                                            (click)="onSubmit(myForm)">
                                        <input type="submit" *ngIf="isEdit" class="btn btn-sm btn-primary"
                                            value="Update" (click)="onSubmit(myForm)">&nbsp;&nbsp;
                                        <button type="button" class="btn btn-sm btn-outline-dark" id='close'
                                            (click)="close()">Cancel</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <!-- Modal Header -->
                                <div class="modal-header py-2">
                                    <h6 class=" modal-title w-100"> Pending Indents </h6>
                                    <button type="button" (click)="closeModal()" class="close"
                                        data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                                </div>
                                <!-- Modal body -->
                                <div class="modal-body">
                                    <div *ngIf="indentPendingList.length">
                                        <table id="table3"
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                <tr>
                                                    <th><input type="checkbox" (change)="checkAll($event)"></th>
                                                    <th>Indent Order No</th>
                                                    <th>Indent Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of indentPendingList">
                                                    <th><input type="checkbox" [(ngModel)]="item.checked"
                                                            [ngModelOptions]="{standalone: true}"></th>
                                                    <td>{{item.indentorder_no}}</td>
                                                    <td>{{item.indent_order_date | date : 'dd/MM/yyyy'}}</td>
                                                    <td>{{item.status == 1?"Active":"Inactive"}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="!indentPendingList.length" class="text-danger text-center">
                                        No records to display
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" (click)="ok()" class="btn btn-sm  btn-save">Add</button>
                                    <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="closeModal()"
                                    data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>