<div class="card-body">
    <div class="row">
        <div class="table-responsive">
            
            <table id="patreggraph" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                class="table table-striped table-bordered"
                style="width:100%;margin-bottom: 25%;">
                <thead class="top">
                    <tr>
                       
                        <th>Sl No.</th>
                        <th>PO Date</th>
                        <th>Supplier Name</th>
                        <th>GRN Date</th> 
                        <th>GRN Qty</th> 
                        <th>Purchase Rate</th> 
                        <th>MRP Price</th> 

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let purchase of historyList">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>