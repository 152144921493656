import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-holding-story-details',
  templateUrl: './holding-story-details.component.html',
  styleUrls: ['./holding-story-details.component.css']
})
export class HoldingStoryDetailsComponent extends BaseComponent implements OnInit {
  storeDetailsList:Array<any>=[];
  
  constructor(CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr)
   }

  ngOnInit(): void {
  }

}
