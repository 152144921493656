<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">List Of GRN</h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <div class="row">

                                                    <div class="col-md-3">
                                                        <label for="sel1">Center</label>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sel1">Supplier Name</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let supplier of supplierList" [value]="supplier.supplier_id">
                                                                {{supplier.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <label for="sel1">From Date</label><span
                                                            class="text-danger">*</span>
                                                        <div class="input-group">
                                                            <input type="date" formControlName="fromDate"
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <label for="sel1">To Date</label><span
                                                            class="text-danger">*</span>
                                                        <div class="input-group">
                                                            <input type="date" formControlName="toDate"
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="footer mt-5 mb-4 text-center">
                                                <button type="button" class="btn btn-outline-success btn-sm" 
                                                    (click)="onSubmit(myForm)">Generate</button>&nbsp;
                                                
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()" id="md_close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>