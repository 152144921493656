<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">
                                            Manufacturer Master</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" (click)="add()"
                                                data-bs-toggle="modal" data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="mmLst.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Manufacturer Name </th>
                                                        <th>Abbreviation</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of mmLst">
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.abbreviation}}</td>
                                                        <td>{{item.status==true?"Active":"Inactive"}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100"> Manufacturer Master</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Manufacturer Master</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Manufacturer Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" formControlName="name"
                                        maxlength="25"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('name').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Abbreviation </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" maxlength="5"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);"
                                        formControlName="abbreviation">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Contact Person Name </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm" maxlength="25"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);"
                                        formControlName="contact_person">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Contact Person Phone</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control form-select-sm"
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        maxlength="10" formControlName="contact_person_mobileno">
                                </div>
                            </div>
                        </div>
                        <div class="well form-horizontal" formGroupName="Address">
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Physical Address </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input placeholder="" class="form-control form-select-sm"
                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                            formControlName="address_1">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Country <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='country_id'
                                            (change)="getStates($any($event.target).value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of cLst' [value]="item.country_id">
                                                {{item.country_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.country_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.country_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    State <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='state_id' (change)="getDistricts($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of sLst' [value]="item.state_id">
                                                {{item.state_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.state_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.state_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    District <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='district_id' (change)="getTowns($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of dLst' [value]="item.district_id">
                                                {{item.district_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.district_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.district_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Town <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <select formControlName='city_id'
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of tLst' [value]="item.city_id">
                                                {{item.city_name}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('Address.city_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.city_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Area <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <div class="input-group">
                                            <input placeholder="" class="form-control form-select-sm"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32);"
                                                formControlName="area_id">
                                        </div>
                                    </div>
                                    <div *ngIf="myForm.get('Address.area_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.area_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    P.O.Box <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input placeholder="" class="form-control form-select-sm" formControlName="zip"
                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                    </div>
                                    <div *ngIf="myForm.get('Address.zip').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('Address.zip').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Status </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="radio" name="status" id="internal" [value]=true checked
                                            formControlName="status">
                                        &nbsp;<label class="form-check-label" for="internal">Active</label>

                                        &nbsp;&nbsp;
                                        <input type="radio" name="status" id="external" [value]=false
                                            formControlName="status">&nbsp;<label class="form-check-label"
                                            for="external">Inactive</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>