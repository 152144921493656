
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Physical Stock Taking</h5>
                                       
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1 btn-sm"
                                            (click)="add()" >Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Search For</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        
                                                    </select>
                                                </div>
                                                <div class="col-md-3 ">
                                                    <label for="sel1">From Date</label>
                                                    <div class="input-group">
                                                        <input type="date" formControlName=""
                                                            class="form-control form-select-sm">
                                                    </div>
                                                </div>
                                                <div class="col-md-3 ">
                                                    <label for="sel1">To Date</label>
                                                    <div class="input-group">
                                                        <input type="date" formControlName=""
                                                            class="form-control form-select-sm">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>&nbsp;

                                        <div >
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Stock Taking Number </th>
                                                        <th> Date </th>
                                                        <th> User Name </th>
                                                        <th> Store Name </th>  
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of stockList">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>