import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.css']
})
export class OrderTypeComponent implements OnInit {
  myForm!: FormGroup;
  isEdit: boolean = false;
  getListData: Array<any> = [];
  orderTypeId: any;
  editResponse: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private CommonService: CommonService, private active: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder) {
    this.active.params.subscribe((res: any) => {
      this.workItemId = res.id;
    })
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      ordertype_name: ['', Validators.required],
      ordertype_code: ['', Validators.required],
      ordertype_description: [''],
      ordertype_status: ['true']
    })
    this.getList();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getList() {
    let payload = { 'tnt_code': localStorage.getItem('TenantCode') }
    this.getListData = [];
    this.CommonService.postCall('FoodOrder/GetList', payload).subscribe((response: any) => {
      setTimeout(() => {
        this.getListData = response;
      }, 1000);
    })
  }

  onSubmit(data: FormGroup) {
    let payload = data.value;
    payload.tnt_code = localStorage.getItem('TenantCode');
    if (this.isEdit) {
      payload['ordertype_modifiedby'] = localStorage.getItem('UserId');
      payload['ordertype_id'] = this.orderTypeId;
      payload.ordertype_modifieddate = new Date();
      this.CommonService.postCall('FoodOrder/Update', payload).subscribe((response: any) => {
        this.getList();
        this.toastr.success("Order Type Created Successfully");
        document.getElementById('md_close').click();
      }, err => { err.error ? err.error : this.toastr.error("Unsuccessfull!") })
    } else {
      payload.ordertype_createdby = localStorage.getItem('UserId');
      payload.ordertype_createddate = new Date();
      this.CommonService.postCall('FoodOrder/Create', payload).subscribe((response: any) => {
        this.getList();
        this.toastr.success("Order Type Created Successfully");
        document.getElementById('md_close').click();
      }, err => { err.error ? err.error : this.toastr.error("Unsuccessfull!") })
    }
  }

  edit(OrderId: any) {
    this.isEdit = true;
    this.orderTypeId = OrderId;
    let payload = { 'ordertype_id': this.orderTypeId }
    this.CommonService.postCall('FoodOrder/Get', payload).subscribe((getResponse: any) => {
      if (getResponse instanceof Array) {
        this.editResponse = getResponse[0]
      } else if (getResponse instanceof Object) {
        this.editResponse = getResponse;
      }
      this.setValues();
    })
  }
  setValues() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      control.setValue(this.editResponse[key])
    });
  }
  add() {
    this.editResponse = {};
    this.isEdit = false;
    this.myForm.reset();
  }
  close() { }
}
