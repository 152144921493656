<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="content-wrapper p-0">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card rounded shadow-lg">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="mb-0">Indent Order</h5>
                            </div>
                            <div class="card-body" [hidden]="!showtable">
                                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                    <div class="row">

                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Store</label>
                                            <div class="input-group col-md-8">
                                                <select formControlName='storeId' 
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="">Select</option>
                                                    <option *ngFor='let item of strLst' [value]="item.store_id">
                                                        {{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Department<span class="text-danger">*</span></label>

                                            <div class="input-group col-md-8">
                                                <select formControlName='DeptId' 
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="">Select</option>
                                                    <option *ngFor='let item of departmentList'
                                                        [value]="item.DEPARTMENT_ID">
                                                        {{item.DEPARTMENT_NAME}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('DeptId').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('DeptId').hasError('required')">
                                                    This field is required.
                                                </span>
                                            </div>

                                        </div>



                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                From Date </label>

                                            <div class="input-group col-md-8">
                                                <input formControlName='fromDate' placeholder='Start Date'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    type="date">
                                            </div>

                                        </div>


                                        <div class="form-group col-md-4 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                To Date </label>

                                            <div class="input-group col-md-8">
                                                <input formControlName='toDate' placeholder='Start Date'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    type="date">
                                            </div>

                                        </div>


                                        <div class="form-group col-md-8 mb-2">
                                            <label class="col-md-4 control-label line_2 rem_1">
                                                Indent Issue Status </label>
                                            <div class="col-md-8 inputGroupContainer">
                                                <input type="radio" name="isssueStatus" id="radio1" value=0 checked
                                                    formControlName="isssueStatus">
                                                &nbsp;<label class="form-check-label" for="internal">Pending</label>
                                                &nbsp;&nbsp;
                                                <input type="radio" name="isssueStatus" id="radio2" value=2
                                                    formControlName="isssueStatus">&nbsp;<label class="form-check-label"
                                                    for="external">Issued</label>
                                                &nbsp;&nbsp;
                                                <input type="radio" name="isssueStatus" id="radio3" value=1
                                                    formControlName="isssueStatus">&nbsp;<label class="form-check-label"
                                                    for="external">All</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type="button" [disabled]="myForm.invalid"
                                                    (click)="onSubmit(myForm)"
                                                    class="btn btn-sm btn-primary mb-1">Search</button>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <div class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary mb-1"
                                        (click)="cancel()">Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-primary mb-1" (click)="add()"
                                        [disabled]="myForm.invalid">Add</button>
                                </div>
                                <div *ngIf="indetOrderGetlist.length">
                                    <table id="patreggraph" datatable
                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                        style="width:100%">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Indent No </th>
                                                <th>Date</th>
                                                <th>Issue No</th>
                                                <th>Department</th>
                                                <th>Indent Issue Status</th>
                                                <th>Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of indetOrderGetlist">
                                                <td>{{item.indentorder_no}}</td>
                                                <td>{{item.indent_order_date | date : 'dd/MM/yyyy'}}</td>
                                                <td>{{item.indentissue_no}}</td>
                                                <td>{{item.department_description}}</td>
                                                <td>{{item.status == true?"Active":"Inactive"}}</td>
                                                <td>{{item.created_by}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            
                            </div>
                            <div class="card">
                                <div class="card-body" [hidden]="showtable">
                                    <form [formGroup]="myForm1">
                                        <div class="row">
                                            <div class="col-md-3 pt-4">
                                                <label for="">Department</label>
                                                <div class="input-group">
                                                    <select formControlName='DeptId' disabled 
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="">Select</option>
                                                        <option *ngFor='let item of departmentList'
                                                            [value]="item.DEPARTMENT_ID">
                                                            {{item.DEPARTMENT_NAME}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="">Indent to Store</label>
                                                <div class="input-group">
                                                    <select formControlName='storeId' disabled 
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="">Select</option>
                                                    <option *ngFor='let item of strLst' [value]="item.store_id">
                                                        {{item.name}}</option>
                                                </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="indentNo">Indent No</label>
                                                <div class="input-group">
                                                    <input type="text" formControlName="indentNo" disabled
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                            <div class="col-md-3 pt-4">
                                                <label for="date">Date</label>
                                                <div class="input-group">
                                                    <input type="date" formControlName="date" disabled
                                                        class="form-control form-select-sm">
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-right mt-4">                                                
                                                <button type="button" class="btn btn-sm btn-primary mb-1"
                                                    data-bs-toggle="modal" data-bs-target="#myModal" (click)="getIndentOrders()">Add</button>
                                            </div>
                                            <table id="table2"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Material Name</th>
                                                        <th>Consumption Unit</th>
                                                        <th>Urgent</th>
                                                        <th>Indent Qty</th>
                                                        <th>QOH</th>
                                                        <th>Issued Qty</th>
                                                        <th>Qty to be received</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of indentOrderList">
                                                        <td>{{item.name1}}</td>
                                                        <td>{{item.sale_unit_name1}}</td>
                                                        <td><input type="checkbox" class="input-checkbox100"
                                                                [(ngModel)]="item.urgent" [ngModelOptions]="{standalone: true}"></td>
                                                        <td><input type="text" class="form-control form-control-sm"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [(ngModel)]="item.indentQty"></td>
                                                        <td>{{item.qoh1}}</td>
                                                        <td>0</td>
                                                        <td>{{item.indentQty}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                    <div class="text-center pt-1 mt-2">
                                        <input type="submit" *ngIf="!isEdit" class="btn btn-sm btn-primary" value="Save"
                                            (click)="onSubmit1(myForm)">
                                        <input type="submit" *ngIf="isEdit" class="btn btn-sm btn-primary"
                                            value="Update" (click)="onSubmit(myForm)">&nbsp;&nbsp;
                                        <button type="button" class="btn btn-sm btn-outline-dark" id='close'
                                            (click)="close()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <!-- Modal Header -->
                                <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                    <h5 class="mb-0">Material Search</h5>
                                    <button type="button" (click)="popClose()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                                </div>
                                <!-- Modal body -->
                                <div class="modal-body">
                                    <div *ngIf="loadIndentOrder.length">                                       
                                        <table id="table3"
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                <tr>
                                                    <th><input type="checkbox" (change)="checkAll($event)"></th>
                                                    <th>Material Name</th>
                                                    <th>QOH</th>
                                                    <th>Unit Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of loadIndentOrder">
                                                    <th><input type="checkbox" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}"></th>
                                                    <td>{{item.name1}}</td>
                                                    <td>{{item.qoh1}}</td>
                                                    <td>{{item.sale_unit_name1}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="!indetOrderGetlist.length" class="text-danger text-center">
                                        No records to display
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" (click)="ok()" class="btn btn-sm  btn-save">Add</button>
                                    <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="popClose()"
                                    data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>