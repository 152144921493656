<div class="card-body">
    <div class="row">
        <div class="table-responsive">
            
            <table id="patreggraph" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                class="table table-striped table-bordered"
                style="width:100%;margin-bottom: 25%;">
                <thead class="top">
                    <tr>
                       
                        <th>Sl No.</th>
                        <th>Store Name</th>
                        <th>Quantity</th> 
                        

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let holding of storeDetailsList">
                        <td></td>
                        <td></td>
                        <td></td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>