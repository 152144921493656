import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-add-grn',
  templateUrl: './add-grn.component.html',
  styleUrls: ['./add-grn.component.css']
})
export class AddGrnComponent  extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm:FormGroup;
  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {

    super(CommonService, toastr);

  }

  ngOnInit(): void {
  }

  onSubmit(from:any) {
   
  }
  close(){
    window.history.back();

  }

}
