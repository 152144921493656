import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-pharmacy-consumption',
  templateUrl: './pharmacy-consumption.component.html',
  styleUrls: ['./pharmacy-consumption.component.css']
})
export class PharmacyConsumptionComponent implements OnInit {
  myForm: FormGroup;

  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    
    this.formInit();

  }

  formInit() {
    this.myForm = this.fb.group({
      fromDate: [((new Date()).toISOString().substring(0, 10))],
      toDate: [((new Date()).toISOString().substring(0, 10))],
    })
   
  }

  onSubmit(form:FormGroup) { 
    
  }

 

  close(){

  }

}
