<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Transactions Summary Report</h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <div class="row">

                                                    <div class="col-md-3">
                                                        <label for="sel1">Center</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sel1">Store</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor='let item of strLst' [value]="item.store_id">
                                                                {{item.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sel1">Material Group</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let material of materialGpList"
                                                                [value]='material.materialgroup_id'>
                                                                {{material.name}}</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sel1">Material Sub Group</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let materials of materialSubGpList"
                                                                [value]='materials.MATERIALSUBGROUP_ID'>
                                                                {{materials.NAME}}</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-3 mt-2">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            From Date </label>

                                                        <div class="input-group col-md-8">
                                                            <input formControlName='fromDate' placeholder='Start Date'
                                                                class="form-control form-control-sm shadow-sm rounded-lg "
                                                                type="date">
                                                        </div>

                                                    </div>


                                                    <div class="form-group col-md-3 mt-2">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            To Date </label>

                                                        <div class="input-group col-md-8 ">
                                                            <input formControlName='toDate' placeholder='Start Date'
                                                                class="form-control form-control-sm shadow-sm rounded-lg "
                                                                type="date">
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-6 mt-2">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            Transaction Type </label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <input type="radio" name="isssueStatus" id="radio1" value=0
                                                                checked formControlName="isssueStatus">
                                                            &nbsp;<label class="form-check-label"
                                                                for="internal">Issues</label>
                                                            &nbsp;&nbsp;
                                                            <input type="radio" name="isssueStatus" id="radio2" value=2
                                                                formControlName="isssueStatus">&nbsp;<label
                                                                class="form-check-label" for="external">Returns</label>

                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                            <div class="footer mt-5 mb-4 text-center">
                                                <button type="button" class="btn btn-outline-success btn-sm"
                                                    (click)="onSubmit(myForm)">Generate</button>&nbsp;

                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    id="md_close">cancel</button>
                                            </div>

                                            <div class="table-responsive mt-5 mb-5">
                                                <table id="patreggraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            <th>Sl No </th>
                                                            <th>Material Name</th>
                                                            <th>Purchase Unit</th>
                                                            <th>Sale Unit</th>
                                                            <th>Maximum Level</th>
                                                            <th>Minimum Level</th>
                                                            <th>ROL</th>
                                                            <th>QOH</th>
                                                            <th>ROQ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table;let i=index">
                                                            <td>{{i+1}}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>