<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.FoodItem}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Food Group</th>
                                                        <th>Food Item Code</th>
                                                        <th>Food Item Name</th>
                                                        <th>Weight/Volume</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.foodgroup_name}}</td>
                                                        <td>{{item.fooditem_code}}</td>
                                                        <td>{{item.fooditem_name}}</td>
                                                        <td>{{item.fooditem_weight}}</td>
                                                        <td>{{item.fooditem_description}}</td>
                                                        <td>{{item.fooditem_status == true ? 'Active' : 'Inactive'}}
                                                        </td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                style="cursor: pointer;" src="" class="edit_icon"
                                                                (click)="edit(item.fooditem_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddFoodItem}}</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100">{{headingName.EditFoodItem}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.FoodGroup}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="foodgroup_id">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of foodGroupList" [value]='item.foodgroup_id'>
                                            {{item.foodgroup_name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('foodgroup_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('foodgroup_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.FoodItemCode}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="fooditem_code">
                                </div>
                                <div *ngIf="myForm.get('fooditem_code').touched">
                                    <span class="text-danger" *ngIf="myForm.get('fooditem_code').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.FoodItemName}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="fooditem_name">
                                </div>
                                <div *ngIf="myForm.get('fooditem_name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('fooditem_name').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.WeightVolume}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="number" placeholder="" class="form-control"
                                        formControlName="fooditem_weight">
                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Status}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="fooditem_status">
                                        <option value="" selected>Select</option>
                                        <option value='true' selected>Active</option>
                                        <option value='false'>InActive </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('fooditem_status').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('fooditem_status').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">
                                {{labelName.Description}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="" type='text' class="form-control"
                                        formControlName="fooditem_description"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                </div>
                                <div *ngIf="myForm.get('fooditem_description').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('fooditem_description').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>