import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
   roleId:number|string=localStorage.getItem('RoleId')||3;
   menus:Array<any>=[]
   clientLogo:any;
   isLogo:boolean=false; 
  constructor( private route: ActivatedRoute, private router:Router,private service  : CommonService ,private sanitizer: DomSanitizer) {
    this.getMenu();

    if(localStorage.getItem('Logo') == ""){
      this.isLogo = true;
    }else{
      this.isLogo=false;
    }

   }

  ngOnInit(): void {
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${localStorage.getItem('Logo')}`);
  }
  getClass(name){
    return "mdi "+name+ " menu-icon"
  }
 
  navigate(path,menu){
    if(!menu.childs.length&&path){
     this.router.navigate(['home/'+path])
    }
  
  }
   getMenu(){
     let payLoad={
      RoleId:parseInt( localStorage.getItem('RoleId')),TENANT_CODE:parseInt(localStorage.getItem('TenantCode'))}
     this.service.postCall('Account/LoadMenusByRoleId',payLoad).subscribe((res)=>{
        this.menus=res;
     },err=>{})
   }
   getId(id:any){
    return id.replaceAll(/\s/g,'')
   }
}
