<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add GRN</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <div class="row">
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> GRN Type</label>
                                                    <div class="input-group">
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1" #grnType>
                                                            <option value="" selected>select</option>
                                                            <option value="Purchase">Purchase</option>
                                                            <option value="Donation">Donation</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1"> &nbsp;</label>
                                                    <div class="form-check form-check-inline mt-4">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio1" value="option1"
                                                            disabled>
                                                        <label class="form-check-label" for="inlineRadio1">Against PO
                                                            {{grnType.value}}</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio2" value="option2"
                                                            disabled>
                                                        <label class="form-check-label" for="inlineRadio2">Without
                                                            PO</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">PO No.</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control form-select-sm" disabled>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Supplier</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control form-select-sm" disabled>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Invoice / Delivery Note</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control form-select-sm">
                                                    </div>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Po Date</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm" disabled>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Invoice/DN Date</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm" disabled>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">GRN Number</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm">
                                                    </div>
                                                </div>


                                                <div class="table-responsive mt-5 mb-5">
                                                    <table id="patreggraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr style="white-space: nowrap;">
                                                                <th> Sl No </th>
                                                                <th> Material Name </th>
                                                                <th> Batch No / Expiry Date </th>
                                                                <th> Unit </th>
                                                                <th> Quantity </th>
                                                                <th> Free Quantity </th>
                                                                <th> Total Quantity </th>
                                                                <th> Rate per Unit </th>
                                                                <th> Net Amount </th>
                                                                <th> Tax(%) </th>
                                                                <th> Tax Amt </th>
                                                                <th> Disc(%) </th>
                                                                <th> Disc Amt </th>
                                                                <th> Total Amount(INR) </th>
                                                                <th> Cost Price </th>
                                                                <th> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>



                                                <div class="col-md-3">
                                                    <label for="sel1">Terms</label>
                                                    <div class="input-group">
                                                        <textarea placeholder="" class="form-control"
                                                            formControlName=""></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Conditions</label>
                                                    <div class="input-group">
                                                        <textarea placeholder="" class="form-control"
                                                            formControlName=""></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Received By</label><span
                                                        class="text-danger">*</span>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" selected>select</option>

                                                    </select>
                                                    <label for="sel1">Online Authorization</label>
                                                    <div class="input-group">
                                                        <input type="checkbox" placeholder="" class="form-check-input"
                                                            formControlName="">
                                                    </div>

                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">Approved Date</label>
                                                    <div class="input-group">
                                                        <input type="date" class="form-control form-select-sm">
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3 pt-4">
                                                        <label for="sel1">Gross Amount</label>
                                                        <div class="input-group">
                                                            <input type="text" disabled
                                                                class="form-control form-select-sm">INR
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 pt-4">
                                                        <label for="sel1">Tax Amount</label>
                                                        <div class="input-group">
                                                            <input type="text" disabled
                                                                class="form-control form-select-sm">INR
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 pt-4">
                                                        <label for="sel1">Discount Amount</label>
                                                        <div class="input-group">
                                                            <input type="text" disabled
                                                                class="form-control form-select-sm">INR
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 pt-4">
                                                        <label for="sel1">Net Amount</label>
                                                        <div class="input-group">
                                                            <input type="text" disabled
                                                                class="form-control form-select-sm">INR
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </form>
                                        <div class="footer mt-5 mb-4 text-center">
                                            <button type="button" class="btn btn-outline-success btn-sm"
                                                (click)="onSubmit(myForm)">Save</button>&nbsp;

                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>