
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">GRN</h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <div class="row">

                                                    <div class="col-md-3">
                                                        <label for="sel1">Search For</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <label for="sel1">From Date</label>
                                                        <div class="input-group">
                                                            <input type="date" formControlName=""
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <label for="sel1">To Date</label>
                                                        <div class="input-group">
                                                            <input type="date" formControlName=""
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-8 mb-2 mt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            Indent Issue Status </label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <input type="radio" name="isssueStatus" id="radio1" value=0
                                                                checked formControlName="isssueStatus">
                                                            &nbsp;<label class="form-check-label"
                                                                for="internal">Pending</label>
                                                            &nbsp;&nbsp;
                                                            <input type="radio" name="isssueStatus" id="radio2" value=2
                                                                formControlName="isssueStatus">&nbsp;<label
                                                                class="form-check-label" for="external">Approved</label>
                                                            &nbsp;&nbsp;
                                                            <input type="radio" name="isssueStatus" id="radio3" value=1
                                                                formControlName="isssueStatus">&nbsp;<label
                                                                class="form-check-label"
                                                                for="external">All</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <button type="button"
                                                                class="btn btn-sm btn-primary mb-1">Search</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div>
                                            <div class="text-right">
                                                <button type="button" class="btn btn-sm btn-danger mb-1"
                                                    (click)="close()">Cancel</button>
                                                &nbsp;&nbsp;
                                                <button type="button" class="btn btn-sm btn-primary mb-1" (click)="add()"
                                                    >Add</button>
                                            </div>
                                            <div>
                                                <table id="patreggraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th></th>
                                                            <th> GRN Number </th>
                                                            <th> GRN Date </th>
                                                            <th> Supplier Name </th>
                                                            <th> Amount </th>
                                                            <th> Created By </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
    
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
    
                                        </div>
                                    </div>&nbsp;

                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>