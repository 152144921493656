<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Inventory Stock Movement For Audit</h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <div class="row">

                                                    <div class="col-md-4 pt-4">
                                                        <label for="sel1">Center</label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-md-4 pt-4">
                                                        <label for="sel1">Store </label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor='let item of strLst' [value]="item.store_id">
                                                                {{item.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4 pt-4">
                                                        <label for="sel1">Material </label><span
                                                            class="text-danger">*</span>
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            id="sel1">
                                                            <option value="" [selected]="true">select</option>
                                                          
                                                        </select>
                                                    </div>
                                                   
                                                    <div class="col-md-4 pt-4 ">
                                                        <label for="sel1">From Date</label><span
                                                            class="text-danger">*</span>
                                                        <div class="input-group">
                                                            <input type="date" formControlName="fromDate"
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 pt-4 ">
                                                        <label for="sel1">To Date</label><span
                                                            class="text-danger">*</span>
                                                        <div class="input-group">
                                                            <input type="date" formControlName="toDate"
                                                                class="form-control form-select-sm">
                                                        </div>
                                                    </div>
                                                   

                                                </div>
                                            </form>
                                            <div class="footer mt-5 mb-4 text-center">
                                                <button type="button" class="btn btn-outline-success btn-sm"
                                                    (click)="onSubmit(myForm)">Generate</button>&nbsp;

                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    id="md_close">cancel</button>
                                            </div>
                                            <div  class="table-responsive mt-5 mb-5">
                                                <table id="patreggraph" datatable class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                                                    <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            
                                                            <th>Transaction Type </th>
                                                            <th>Date</th>
                                                            <th>Reference No.</th>
                                                            <th>Details</th>
                                                            <th>Purchase Unit</th>
                                                            <th>Receipts</th>
                                                            <th>Sale Unit</th>
                                                            <th>Issues</th>
                                                            <th>Closing Balance</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table;let i=index">
                                                            
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                          
                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

