import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-parital-service-pos-report',
  templateUrl: './parital-service-pos-report.component.html',
  styleUrls: ['./parital-service-pos-report.component.css']
})
export class ParitalServicePosReportComponent implements OnInit {
  myForm: FormGroup;
  
  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private router: Router) { }


  ngOnInit(): void {
 
    this.formInit();

  }

  formInit() {
    this.myForm = this.fb.group({
      fromDate: [((new Date()).toISOString().substring(0, 10))],
      toDate: [((new Date()).toISOString().substring(0, 10))],
    })

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

 

  onSubmit(form: FormGroup) {

  }



  close() {

  }
}
