<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.HeadPrivileges}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Assign Privileges</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th>User Name </th>
                                                        <th>Management</th>
                                                        <th> Course Name </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.username}}</td>
                                                        <td>{{item.is_management == 1 ? 'Principal' : 'HOD'}}</td>
                                                        <td>{{item.course_name == null ? 'NA' : item.course_name}}</td>
                                                        <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.userid)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x ">
                                                                </i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AssignPrivileges}} </h4>
                <h4 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditPrivileges}} </h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label mt-2">
                                 {{labelName.Management}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName='is_management' (change)="managementChange($event.target.value)">
                                        <option value="" selected>Select</option>
                                        <option [value]=1>Principal</option>
                                        <option [value]=2>HOD</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4" *ngIf="isUserId">
                            <label class="col-md-4 control-label line_2 rem_1">
                               {{labelName.Users}} <span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="userid"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of userDropdown' [value]="item.userid">
                                            {{item.username}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('userid').touched">
                                    <span class="text-danger" *ngIf="myForm.get('userid').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4" *ngIf="isCourse">
                            <label class="col-md-4 control-label line_2 rem_1">
                               {{labelName.Course}} <span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='management_course'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of courseDropdown' [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('management_course').touched">
                                    <span class="text-danger" *ngIf="myForm.get('management_course').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn  btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>