<div class="row">
    <div class="col-xl-12">
        <div class="card rounded shadow-lg">
            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">

               
            </div>
            <div class="card-body">
                <div class="text-right">
                    <button type="button" class="btn btn-primary mb-1 btn-sm" (click)="add()" data-bs-toggle="modal"
                        data-bs-target="#myModal">Add</button>
                </div>
                <div class="row">
                    <div *ngIf="preferredSupplierList.length">
                        <table id="patreggraph" datatable
                            class=" table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>

                                    <th>Sl No.</th>
                                    <th>Supplier Name</th>
                                    <th>Preferred</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let preferred of preferredSupplierList;let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{preferred.name}}</td>
                                    <td></td>
                                    <td>{{preferred.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModal" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg ">
        <div class="modal-content">

            <div class="modal-header">
                <h6 class=" modal-title w-100">Supplier Details </h6>
                <button type="button" (click)="cancel()" class="btn-close btn-close-dark" aria-label="Close"
                    data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" >
                    <fieldset>
                        <div class="row">
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label">Suppliers </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                        <ng-multiselect-dropdown [placeholder]="'Enter Supplier names'"
                                            [settings]="dropdownSettings" [data]="supplierList"
                                            formControlName="name" (onSelect)="onItemSelect($event)"
                                            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                                            (onDeSelectAll)="onDeSelectAll()">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </form>



            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-success" id='close_id' (click)="save(myForm)"
                    data-bs-dismiss="modal">Save</button>
                <button type="button" class="btn btn-sm btn-danger" id='close_id' (click)="cancel()"
                    data-bs-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>